
/**************************************/
/*********** POP'UP MESSAGE ***********/
/**************************************/
.white-popup-block {
    width: auto;
	
	position: relative;
	padding: 40px;
	max-width: 600px;
    margin: 20px auto;

	background-color: $white;

	ul {
		margin: 0;
	}
}
/*
button.mfp-close {
	line-height: 46px;
	top: auto;
	bottom: 100%;
	right: -14px;
	
	transition: transform 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		transform: rotate(180deg);
	}
}

.mfp-close-btn-in .mfp-close {
	color: $white;
}*/