html { font-size: 62.5%; }

$font-base:      'ralewayregular', sans-serif;
$font-light:     'ralewaylight', sans-serif;
$font-semibold:  'ralewaysemibold', sans-serif;
$font-bold:      'ralewaybold', sans-serif;
$font-extrabold: 'ralewayextrabold', sans-serif;
$font-alt:       'playfair_displayitalic', serif;
$font-price:     'Lato', sans-serif;
$font-icon:      'operavendome';
$image-path:     '/images/';

$color-1:         #3f3e46;
$color-1-lighten: #514f5c;
$color-1-darken:  #27262b;
// $color-2:         #A8E6CE;

$white: #fff;
$black: #000;

$color-bg:        $white;
$color-bg-darken: #dee0e6;

$color-text:        $color-1;
$color-text-light:  $white;

$color-cancel:  #DB1B46;
$color-success: #7AA322;

$color-link:       lighten($color-1-lighten, 10%);
$color-link-hover: $color-1-darken;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 30px / 2;
$grid-gutter-wide:    30px / 2;