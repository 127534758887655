html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-1-lighten;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
*/
/***** TITLES *****/
.title-lev1 {
	position: relative;
	z-index: 2;
	
	min-width: 75%;
	padding: 15px 30px;
	background-color: rgba($color-1, 0.3);
	border: 1px solid $white;
	
	text-align: center;
	@include center;

	@include mq($from: tablet) {
		padding: 20px 40px;
		min-width: 60%;
	}

	@include mq($from: desktop) {
		padding: 25px 60px;
		min-width: 55%;
	}

	@include mq($from: wide) {
		padding: 30px 70px;
	}

	@include mq($from: xwide) {
		padding: 35px 80px;
	}

	span {
		display: block;
	}

	.title-main {
		font-family: $font-semibold;
		@include font-size(16);
		color: $color-text-light;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		text-align: center;
		text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
		line-height: 1.3em;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@include mq($from: tablet) {
			@include font-size(18);
		}

		@include mq($from: desktop) {
			@include font-size(22);
		}

		@include mq($from: wide) {
			@include font-size(25);
			letter-spacing: 0.3em;
		}

		@include mq($from: xwide) {
			@include font-size(28);
		}
	}

	.title-alt {
		margin: 0.2em 0 0 0;

		font-family: $font-alt;
		@include font-size(13);
		color: $color-text-light;
		text-align: center;
		text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@include mq($from: tablet) {
			@include font-size(14);
		}

		@include mq($from: desktop) {
			@include font-size(16);
		}

		@include mq($from: wide) {
			@include font-size(18);
		}

		@include mq($from: xwide) {
			@include font-size(20);
		}

		span.icon-pointer {
			display: inline-block;
			margin: 0 4px 0 0;
		}
	}
}

.title-lev1--collection {
	padding: 40px 20px 16px 20px;

	@include mq($until: tablet) {
		min-width: 80%;
	}

	@include mq($from: tablet) {
		padding: 47px 30px 18px 30px;
	}

	@include mq($from: desktop) {
		padding: 56px 30px 24px 30px;
	}

	@include mq($from: xwide) {
		padding: 65px 50px 35px 50px;
	}

	.category__icon {
		width: 60px;
		height: 60px;
		margin-top: -30px;
		margin-left: -30px;

		@include mq($from: tablet) {
			width: 70px;
			height: 70px;
			margin-top: -35px;
			margin-left: -35px;
		}

		@include mq($from: desktop) {
			width: 80px;
			height: 80px;
			margin-top: -40px;
			margin-left: -40px;
		}

		@include mq($from: wide) {
			width: 90px;
			height: 90px;
			margin-top: -45px;
			margin-left: -45px;
		}

		.hexagon-container .hexagon {
			fill: $color-1;
			stroke: $white;
		}

		.icon-cat {
			color: white;
			@include font-size(36);

			@include mq($from: tablet) {
				@include font-size(40);
			}

			@include mq($from: desktop) {
				@include font-size(48);
			}

			@include mq($from: wide) {
				@include font-size(56);
			}
		}
	}
}

.title-lev2,
.title-lev3 {
	margin: 0 auto 1.2em auto;
	max-width: 80%;

	font-family: $font-alt;
	color: $color-text;
	text-align: center;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include mq($from: tablet) {
		max-width: 70%;
	}

	@include mq($from: desktop) {
		margin: 0 auto 1.3em auto;
		max-width: 60%;
	}

	@include mq($from: wide) {
		margin: 0 auto 1.5em auto;
		max-width: 55%;
	}

	@include mq($from: xwide) {
		margin: 0 auto 1.5em auto;
	}
}

.title-lev2 {
	@include font-size(20);

	@include mq($from: tablet) {
		@include font-size(24);
	}

	@include mq($from: desktop) {
		@include font-size(26);
	}

	@include mq($from: wide) {
		@include font-size(28);
	}

	@include mq($from: xwide) {
		@include font-size(30);
	}

	&:after {
		display: block;
		margin-top: 6px;

		font-family: $font-icon;
		content: "\e903";
		@include font-size(14);

		@include mq($from: tablet) {
			margin-top: 10px;
			@include font-size(16);
		}

		@include mq($from: desktop) {
			@include font-size(18);
		}

		@include mq($from: wide) {
			@include font-size(20);
		}

		@include mq($from: xwide) {
			@include font-size(21);
		}
	}
}

.title-lev3 {
	@include font-size(17);

	@include mq($from: tablet) {
		@include font-size(20);
	}

	@include mq($from: desktop) {
		@include font-size(22);
	}

	@include mq($from: wide) {
		@include font-size(24);
	}

	@include mq($from: xwide) {
		@include font-size(26);
	}

	&:after {
		display: block;
		content: "";
		width: 14px;
		height: 1px;
		background-color: $color-1;
		margin: 6px auto 0 auto;

		@include mq($from: tablet) {
			margin-top: 10px;
		}
	}
}

.title-lev3--align-left {
	margin-right: 0;
	margin-left: 0; 
	
	text-align: left;

	&:after {
		margin-right: 0;
		margin-left: 0;
	}
}

/***** TEXT *****/
.align-left { text-align: left !important; }
.align-right { text-align: right !important; }

p {
	margin: 0 0 1.1em 0;
	font-family: $font-base;
	line-height: 1.3em;

	&.mention {
		margin: 2.5em 0 0 0;

		font-style: italic;
		color: #999;
	}
}


a {
	color: $color-1-lighten;

	transition: color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-text;
	}
}

a.underlined-link {
	color: $color-1-lighten;
	border-bottom: 1px dotted $color-1-lighten;

	transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-text;
		border-color: transparent;
	}
}

a.main-link,
button.main-link {
	display: inline-block;
	height: 36px;
	line-height: 38px;
	position: relative;

	padding: 0 30px 0 20px;
	background-color: $color-1;
	border: none;

	font-family: $font-base;
	@include font-size(11);
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: $color-text-light;

	transition: background-color 0.2s ease-in-out;

	@include mq(tablet, desktop) {
		padding: 0 25px 0 15px;
	}

	&:after {
		font-family: $font-icon;
		content: "\e910";
		@include font-size(8);

		position: absolute;
		top: 0;
		right: 18px;

		transition: right 0.2s ease-in-out;

		@include mq(tablet, desktop) {
			right: 14px;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-text-light;
		background-color: $color-1-darken;

		&:after {
			right: 12px;

			@include mq(tablet, desktop) {
				right: 10px;
			}
		}
	}

	&.until-tablet {
		margin: 20px 0 0 0;
	}

	&.main-link--alt {
		padding: 0 20px 0 30px;

		&:after {
			transform: rotate(180deg);
			top: -1px;
			right: auto;
			left: 17px;

			transition: left 0.2s ease-in-out;
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {

			&:after {
				right: auto;
				left: 12px;
			}
		}
	}

	&.main-link--filter {
		padding: 0 15px;
		line-height: 34px;
		background-color: transparent;
		border: 1px solid $color-1-lighten;
		border-color: lighten($color-1-lighten, 15%);

		font-family: $font-alt;
		text-transform: initial;
		@include font-size(14);
		color: $color-text;

		transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

		@include mq(tablet, desktop) {
			padding: 0 10px;
			line-height: 33px;
			@include font-size(13);
		}

		&:after {
			content: "";
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active,
		&.current {
			color: $color-text-light;
			background-color: $color-1-lighten;
			border-color: $color-1-lighten;
		}
	}
}

.strong {
	font-family: $font-semibold;
}

ul {
	margin: 2.5em 0 0 0;

	li {
		margin: 0 0 1em 0;
	}
}

ul.main-list {
	width: auto;
	margin: 1.8em auto 2em auto;

	
	li {
		display: block;
		width: auto;

		text-align: left;
	}

	&.main-list--category {

		li {
			position: relative;
			padding-left: 28px;

			span.icon {
				position: absolute;
				top: -4px;
				left: -3px;

				&:before {
					@include font-size(25);
				}
			}
		}
	}

	&:not(.main-list--category) {

		li {
			position: relative;
			padding-left: 15px;

			&:before {
				position: absolute;
				top: 3px;
				left: -2px;

				font-family: $font-icon;
				content: "\e910";
				@include font-size(12);
			}
		}
	}

	.adherent-register-resume &,
	.adherent-home &,
	.evenement-detail & {

		li {
			margin-bottom: 0.6em;
		}
	}

	.adherent-register-resume & {
		margin-bottom: 0;
	}

	.evenement-detail & {
		margin-top: 40px;
	}
}

/***** EDITOR-TEXT *****/
.editor-text {
	color: $color-text;

	p {
		font-family: $font-base;
		line-height: 1.3em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;
		border-color: lighten($color-link, 10%);

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 25%);
			}
		}
	}

	strong {
		font-family: $font-semibold;
	}

	ul, ol {
		width: auto;
		margin: 1.8em auto 2em auto;
		
		li {
			display: block;
			width: auto;
			margin: 0 0 0.5em 0;

			text-align: left;

			&:before {
				margin: 0 3px 0 0;
			}
		}
	}

	ul {
		
		li {
			&:before {
				// font-family: $font-icon;
				// content: "\e910";
				// @include font-size(11);
				
				display: inline-block;
				content: "";
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $color-1;
				margin: 0 6px 0 0;
			}
		}
	}

	ol {
		counter-reset: my-counter;
		
		li {
			
			&:before {
				content: counter(my-counter)".";
				counter-increment: my-counter;

				margin: 0 5px 0 0;
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}

/* 
 ######    ########    ######    ########   ####   #######   ##    ##   
##    ##   ##         ##    ##      ##       ##   ##     ##  ###   ##   
##         ##         ##            ##       ##   ##     ##  ####  ##   
 ######    ######     ##            ##       ##   ##     ##  ## ## ##   
      ##   ##         ##            ##       ##   ##     ##  ##  ####   
##    ##   ##         ##    ##      ##       ##   ##     ##  ##   ###   
 ######    ########    ######       ##      ####   #######   ##    ##   
*/
.section {
	background-color: $color-bg;
	width: 100%;

	&.section--bg-darken {
		background-color: $color-bg-darken;
	}
}

.section--content {
	padding-top: 130px;

	transition: padding-top 0.2s ease-in-out;

	@include mq($from: tablet) {
		padding-top: 90px;
	}

	@include mq($from: desktop) {
		padding-top: 80px;

		.ontop & {
			padding-top: 110px;
		}
	}
}


/* 
##      ## ########      ###     ########   ########   ########   ########   
##  ##  ## ##     ##    ## ##    ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ##     ##   ##   ##   ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ########   ##     ##  ########   ########   ######     ########   
##  ##  ## ##   ##    #########  ##         ##         ##         ##   ##    
##  ##  ## ##    ##   ##     ##  ##         ##         ##         ##    ##   
 ###  ###  ##     ##  ##     ##  ##         ##         ########   ##     ##  
*/
.wrapper {
	@include clearfix;
	padding: 20px 15px;
	margin: 0 auto;

	@include mq($from: tablet) {
		padding: 40px 0;
		width: 700px;
	}

	@include mq($from: desktop) {
		width: 920px;
	}

	@include mq($from: wide) {
		padding: 50px 0;
		width: 1120px;
	}

	@include mq($from: xwide) {
		padding: 60px 0;
		width: 1280px;
	}
}

/***** WRAPPER--HEADER *****/
.wrapper--header {
	padding: 8px 10px;

	@include mq(tablet, desktop) {
		width: auto;
		margin: 0;
		padding: 8px 20px;
	}

	@include mq($from: desktop) {
		margin: 0 auto;
		padding: 8px 0;

		transition: padding 0.2s ease-in-out;

		.ontop & {
			padding: 18px 0;
		}
	}
}

.wrapper--header-top {
	padding: 0;

	@include mq(tablet, desktop) {
		height: 100%;
		padding: 0 20px;
	}

	@include mq($from: desktop) {
		padding: 0 !important;
	}
}

/***** WRAPPER--MENU *****/
.wrapper--menu {

	@include mq($from: tablet) {
		padding: 0;
	}
}

/***** WRAPPER--FOOTER *****/
.wrapper--footer {

	@include mq($from: tablet) {
		padding: 20px 0;
	}

	@include mq($from: desktop) {
		padding: 25px 0;
	}

	@include mq($from: wide) {
		padding: 30px 0;
	}
}

/***** WRAPPER--SIDEBAR *****/
.wrapper--adherent {
	padding: 0 15px;

	@include mq($from: tablet) {
		padding: 0;
	}
}


/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.page-content {
	margin: 0 auto 40px auto;
	
	@include mq($from: tablet) {
		max-width: 80%;
		margin: 0 auto 60px auto;
		text-align: center;
	}

	@include mq($from: desktop) {
		max-width: 60%;
		margin: 0 auto 80px auto;
	}

	@include mq($from: wide) {
		max-width: 52%;
		margin: 0 auto 100px auto;
	}

	@include mq($from: xwide) {
		max-width: 46%;
	}

	&.page-content--cgu {
		text-align: left;

		@include mq($from: tablet) {
			max-width: 85%;
		}

		@include mq($from: desktop) {
			max-width: 75%;
		}

		@include mq($from: wide) {
			max-width: 65%;
		}

		@include mq($from: xwide) {
			max-width: 60%;
		}

		p {
			@include font-size(14);

			&.strong {
				margin-bottom: 0.2em;
			}
		}

		.main-list {
			margin-top: 12px;
			margin-bottom: 20px;

			li {
				margin-bottom: 0.6em;
			}
		}
	}
}

/***** PRICE TABLE *****/
.price-table {
	@include clearfix;
	margin: 0 auto;
	text-align: center;
	max-width: 100%;

	@include mq($from: mobileLandscape) {
		max-width: 60%;
	}

	@include mq($from: tablet) {
		max-width: 100%;
	}
}

.price-table__item {
	width: 100%;
	height: auto;
	margin: 0 auto 20px auto;
	border: 1px solid $color-1;

	text-align: center;

	@include mq($from: tablet) {
		display: inline-block;
		width: 35%;
		margin: 10px;
		padding: 0;
	}

	@include mq($from: desktop) {
		width: 22%;
		margin: 10px;
	}

	@include mq($from: wide) {
		width: 20%;
		margin: 15px;
	}
}

.price-table__item--title {
	padding: 14px 0 18px 0;
	background-color: $color-1-lighten;

	font-family: $font-alt;
	@include font-size(18);
	color: $color-text-light;

	@include mq($from: xwide) {
		@include font-size(19);
	}

	.no-touch & {
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
	}
}

.price-table__item--content {
	padding: 20px;

	@include mq($from: desktop) {
		padding: 10px;
	}

	@include mq($from: wide) {
		padding: 20px;
	}
}

.price-table__item--subtitle {
	margin: 0 0 14px 0;
	font-family: $font-semibold;
	@include font-size(15);
	color: $color-text;

	@include mq($from: xwide) {
		margin: 0 0 16px 0;
		@include font-size(16);
	}

	.no-touch & {
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
	}

	&:after {
		display: block;
		content: "";
		width: 12px;
		height: 1px;
		background-color: $color-1;
		margin: 12px auto 0 auto;

		@include mq($from: xwide) {
			margin: 14px auto 0 auto;
		}
	}
}

.price-table__item--price {
	margin: 0 0 20px 0;

	@include mq($from: xwide) {
		margin: 0 0 25px 0;
	}

	span {
		display: block;

		&.price-text {
			font-family: $font-alt;
			margin: 0 auto 0.2em auto;

			@include mq($from: desktop) {
				@include font-size(13);
			}

			@include mq($from: wide) {
				@include font-size(14);
			}

			@include mq($from: xwide) {
				margin: 0 auto 0.3em auto;
			}
		}

		&.price-amount {
			font-family: $font-price;
			font-weight: 300;
			@include font-size(30);

			@include mq($from: xwide) {
				@include font-size(31);
			}
		}
	}
}

.price-table__item--link {
	display: block;
	background-color: $color-1;
	padding: 14px 0 15px 0;

	font-family: $font-base;
	@include font-size(11);
	letter-spacing: 0.1em;
	color: $color-text-light;
	text-transform: uppercase;

	@include mq($from: wide) {
		padding: 17px 0 18px 0;
	}

	&:after {
		margin: 0 0 0 5px;
		font-family: $font-icon;
		content: "\e910";
		@include font-size(9);

		transition: margin-left 0.2s ease-in-out;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-text-light;

		&:after {
			margin-left: 10px;
		}
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
/***** HEADER-SOCIAL *****/
.header-top {
	width: 100%;
	height: 40px;
	background-color: lighten($color-1-darken, 12%);
	z-index: 120;

	@include mq($until: desktop) {
		position: fixed;
		z-index: 110;
	}

	@include mq($from: tablet) {
		height: 30px;
		padding: 0;
		margin: 0;
	}

	@include mq($from: desktop) {
		position: absolute;
	}

	.header-top--content {
		height: 100%;
		width: auto;

		@include mq($from: tablet) {
			float: right;
			width: 100%;
		}
	}

	.header-top--content__right {
		position: relative;
		height: 30px;
		background-color: darken($color-1-lighten, 2%);

		@include mq($from: tablet) {
			float: right;
			background-color: transparent;
		}
	}

	.social-list {
		width: auto;
		height: 100%;
		margin: 0;
		padding: 0 0 0 10px;

		@include mq($until: tablet) {
			float: left;
		}

		@include mq($from: tablet) {
			display: inline-block;
			padding: 0 10px 0 0;
		}

		li {
			display: inline-block;
			float: left;
			margin: 0 6px 0 0;

			&:last-of-type {
				margin: 0;
			}

			a {
				display: inline-block;
				float: left;
				color: $white;
				margin: 0;
				padding: 0;
				height: 30px;
				line-height: 28px;
				transform: scale(1);

				text-align: center;
				@include font-size(16);

				transition: transform 0.2s ease-in-out;

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					transform: scale(1.2);
					line-height: 28px;
				}
			}
		}
	}

	.connect-list {
		display: inline-block;
		width: auto;
		height: 30px;
		margin: 0;
		padding: 0;

		@include mq($until: tablet) {
			float: right;
		}

		li {
			display: inline-block;
			float: left;
			background-color: lighten($color-1-lighten, 2%);
			margin: 0 1px 0 0;

			&:last-of-type {
				margin: 0;
			}

			a {
				display: inline-block;
				float: left;

				height: 30px;
				line-height: 30px;
				width: auto;
				margin: 0;
				padding: 0 12px;
				vertical-align: middle;
				position: relative;
				z-index: 1;
				overflow: hidden;

				text-align: center;
				@include font-size(12);
				color: rgba($color-text-light, 0.75);

				transition: background-color 0.3s;
				transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

				&:after, 
				span {
					display: block;
					width: 100%;
					height: 100%;

					transition: transform 0.3s, opacity 0.3s;
					transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
				}

				&:after {
					content: attr(data-text);
					position: absolute;
					top: 0;
					left: 0;

					color: rgba($color-text-light, 0.8) !important;

					opacity: 0;
					transform: translate3d(0, 40%, 0);
				}

				span {
					vertical-align: middle;
				}

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					background-color: $color-1-darken;

					&:after {
						opacity: 1;
						transform: translate3d(0, 0, 0);
					}

					span {
						opacity: 0;
						transform: translate3d(0, -25%, 0);
					}
				}
			}

			&.contact-link {
				background-color: lighten($color-1-lighten, 12%);

				a {
					color: $color-text-light;

					&:after {
						color: $color-text-light !important;
					}

					.no-touch &:hover,
					.no-touchevents &:hover,
					.no-touch &:focus,
					.no-touchevents &:focus,
					.touch &:active,
					.touchevents &:active {
						background-color: darken($color-1-darken, 20%);
						background-color: $white;

						&:after {
							color: $color-1 !important;
						}
					}
				}
			}
		}
	}
}

/***** RECHERCHE HEADER *****/
.sb-search {
	position: relative;
	width: 0%;
	min-width: 30px;
	height: 30px;

	float: left;
	margin: 0 12px 0 0;
	overflow: hidden;

	transition: width 0.3s ease-in-out;
	-webkit-backface-visibility: hidden;
}

.sb-search-input {
	position: absolute;
	top: 0;
	right: 0;

	width: 100%;
	height: 30px;
	border: none;
	outline: none;

	margin: 0;
	padding: 10px 35px 10px 10px;
	z-index: 10;

	background-color: lighten($color-1-lighten, 8%);

	font-family: $font-base;
	@include placeholder {
	    color: $color-text-light;
		@include font-size(12);
	}
	@include font-size(12);
	color: $color-text-light;
}

.sb-search-input::-webkit-input-placeholder,
.sb-search-input:-moz-placeholder,
.sb-search-input::-moz-placeholder,
.sb-search-input:-ms-input-placeholder {
	color: $color-text;
}

.sb-icon-search,
.sb-search-submit  {
	display: block;
	width: 30px;
	height: 30px;
	line-height: 30px;

	position: absolute;
	right: 0;
	top: 0;

	padding: 0;
	margin: 0;

	text-align: center;
	cursor: pointer;
}

.sb-search-submit {
	background: $color-bg; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}

.sb-icon-search {
	z-index: 90;
	background-color: $color-1;

	font-family: $font-icon;
	@include font-size(15);
	color: rgba($white, 0.8);

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:before {
		content: '\e905';
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		background-color: darken($color-1, 4%);
	}
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
	width: 210px;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
	z-index: 11;
	background-color: darken($color-1, 8%);
	color: $white;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
	z-index: 90;
}

/***** SELECT LANGUES *****/
.lang-list {
	overflow: hidden;
	margin: 0;
	height: 40px;

	@include mq($from: tablet) {
		height: 30px;
		float: left;
	}

	li {
		display: block;
		float: left;
		margin-left: 1px;
		padding: 0;

		a {
			display: block;
			position: relative;
			vertical-align: top;
			margin: 0;
			width: 40px;
			height: 40px;
			line-height: 40px;
			overflow: hidden;
			background-color: rgba($color-1, 0.25);

			font-family: $font-bold;
			@include font-size(10);
			text-transform: uppercase;
			text-align: center;
			letter-spacing: 0.05em;
			color: $white;

			transition: background-color 0.25s ease-in-out;

			@include mq($from: tablet) {
				width: 30px;
				height: 30px;
				line-height: 30px;
			}

			span {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 1;
				color: rgba($white, 0.9);
				border: none;

				transition: top 0.25s ease-in-out, opacity 0.2s ease-in-out;
			}

			&:after {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 40px;
				left: 0;

				content: attr(data-lang);
				color: $white;

				transition: top 0.25s ease-in-out;

				@include mq($from: tablet) {
					top: 30px;
				}
			}

			.no-touch & {
				&:hover {
					color: $white;
					background-color: rgba($white, 0.15);

					span {
						top: -40px;
						opacity: 0;

						@include mq($from: tablet) {
							top: -30px;
						}
					}

					&:after {
						top: 0;
					}
				}
			}

			&.current {
				background-color: rgba($white, 0.15);

				span {
					top: -40px;
					opacity: 0;

					@include mq($from: tablet) {
						top: -30px;
					}
				}

				&:after {
					top: 0;
				}
			}
		}
	}

	@include mq($until: tablet) {
		@include clearfix;
		width: 100%;
		height: 40px;
		margin: 0;
		position: relative;

		li {
			margin: 0;
			width: 40px;

			a { 
				width: 100%;
			}
		}
	}
}

/***** HEADER-MAIN *****/
.header-main {
	position: relative;
	z-index: 115;

	@include mq($from: desktop) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 50px;
		background-color: $color-1-darken;
		z-index: 115;

		transition: height 0.2s ease-in-out;

		.ontop & {
			position: absolute;
			top: 30px;
			height: 80px;
		}
	}
}

/***** LOGO *****/
.logo-mobile--container {
	
	@include mq($until: desktop) {
		height: 60px;
		width: 100%;
		position: fixed;
		z-index: 115;
		background-color: $color-1-darken;
	}

	@include mq($until: tablet) {
		top: 70px;
	}

	@include mq(tablet, desktop) {
		top: 30px;
	}
}

.logo {
	display: block;
	text-indent: -9999px;
	position: relative;
	z-index: 115;
	
	width: 185px;
	height: 43px;
	background: transparent url('#{$image-path}logo.png') 0 0 no-repeat;
	background-size: 185px 43px;

	@include mq($until: tablet) {
		margin: 0 auto;
	}
	
	@include retina {
		background-image: url('#{$image-path}logo@2x.png');
		background-size: 185px 43px;
	}

	@include mq($from: desktop) {
		width: 150px;
		height: 35px;
		background-size: 150px 35px;
		z-index: 120;

		transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-size 0.2s ease-in-out;

		@include retina {
			background-image: url('#{$image-path}logo@2x.png');
			background-size: 150px 35px;
		}

		.ontop & {
			width: 185px;
			height: 43px;
			background-size: 185px 43px;
		}
	}
}


/* 
##    ##      ###     ##     ##  
###   ##     ## ##    ##     ##  
####  ##    ##   ##   ##     ##  
## ## ##   ##     ##  ##     ##  
##  ####   #########   ##   ##   
##   ###   ##     ##    ## ##    
##    ##   ##     ##     ###     
*/
.navigation {
	z-index: 115;

	@include mq($until: desktop) {
		display: block;
		transition: right 0.35s ease-in-out;
		position: fixed;
		top: 0;
		bottom: 0;
		right: -100%;
		width: 100%;
		background-color: rgba($color-1-darken, 0.9);

		&.open {
			right: 0;
		}
	}

	@include mq($from: desktop) {
		width: 100%;
		height: 50px;
		line-height: 50px;
		position: absolute;
		top: 0;

		transition: top 0.2s ease-in-out, line-height 0.2s ease-in-out;

		.ontop & {
			height: 80px;
			line-height: 80px;
		}
	}
}

.nav-container {

	@include mq($from: desktop) {
		float: right;
	}
}

.menu {
	clear: both;
	@include clearfix;

	@include mq($until: desktop) {
		height: 78vh;
		margin: 10vh auto 12vh auto;

		text-align: center;
	}

	@include mq($from: desktop) {
		display: inline-block;
	}

	li {
		@include mq($until: desktop) {
			height: 8vh;
			line-height: 8vh;
		}

		@include mq($from: desktop) {
			display: inline-block;
			margin: 0 6px 0 0;
		}

		@include mq($from: wide) {
			margin: 0 8px 0 0;
		}

		&:last-of-type {
			margin: 0;
		}
	}

	a {
		display: inline-block;
		position: relative;
		padding: 0 20px;
		margin: 4px auto;
		vertical-align: middle;

		text-align: center;
		transition: color 0.3s;

		font-family: $font-base;
		@include font-size(15);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $color-text-light;
		line-height: 1.2em;
		
		transition: color 0.2s ease-in-out;

		@include mq($from: desktop) {
			padding: 0;
			margin: 0;
			width: auto;

			@include font-size(10);
			letter-spacing: 0.02em;
		}

		@include mq($from: wide) {
			@include font-size(11);
			letter-spacing: 0.035em;

			transition: font-size 0.2s ease-in-out;
		}

		&:before,
		&:after {
			display: inline-block;
			opacity: 0;

			font-family: $font-bold;

			transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
		}

		&:before {
			content: "{";
			transform: translateX(12px);
		}

		&:after {
			content: "]";
			transform: translateX(-12px);
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active,
		&.current {
			color: $color-text-light;
			
			&:before,
			&:after {
				transform: translateX(0);
				opacity: 1;
			}

			&.menu-actualite {
				&:before { transform: translateX(-20px); }
				&:after { transform: translateX(20px); }

				@include mq($from: desktop) {
					&:before { transform: translateX(-12px); }
					&:after { transform: translateX(9px); }
				}
			}

		}

		&.menu-actualite {
			padding: 0;

			.plus {
				font-size: 26px;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -10px;
			}

			@include mq($from: desktop) {
				margin-left: 14px;

				.plus {
					font-size: 20px;
					margin-top: -7px;
				}
			}
		}
	}
}

.select-lang {
	position: relative;

	@include mq($from: desktop) {
		display: inline-block;
		margin: 0 0 0 12px;
	}

	@include mq($from: wide) {
		display: inline-block;
		margin: 0 0 0 16px;
	}

	&::before {
		display: block;

		position: absolute;
		top: 2px;
		right: 6px;

		font-family: $font-icon;
		content: '\e910';
		color: lighten($color-1-lighten, 20%);
		@include font-size(9);

		transform: rotate(90deg);
	}

	select {
		position: relative;
		width: 100%;
		height: 20px;
		background: transparent;
		border: 1px solid $color-1-lighten;
		border-radius: 0;
		padding: 0 12px 0 6px;
		-webkit-appearance: none;
		vertical-align: middle;

		font-family: $font-bold;
		@include font-size(10);
		color: $color-text-light;
	}
}


/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
	position: fixed;
	width: 40px;
	height: 40px;
	top: 0;
	right: 0;
	z-index: 120;

	@include mq($from: tablet) {
		width: 60px;
		height: 60px;
		top: 30px;
	}

	@include mq($from: desktop) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background: lighten($color-1-lighten, 25%);
	transition: 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: 20px;
	}
}

.lines-button {
	position: absolute;
	height: auto;
	width: auto;
	right: 11px;
	top: 7px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	@include mq($from: tablet) {
		right: 20px;
		top: 17px;
	}

	.no-touch .nav-toggle:hover &:not(.close) .lines, 
	.no-touch .nav-toggle:hover & .lines:before, 
	.no-touch .nav-toggle:hover & .lines:after {
		background: lighten($color-1-lighten, 10%);
	}

	@include mq($from: desktop) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background: transparent;

	&:before, &:after {
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}

/* 
 ######     #######    #######   ##    ##   ####  ########   ########      ###     ########   
##    ##   ##     ##  ##     ##  ##   ##     ##   ##         ##     ##    ## ##    ##     ##  
##         ##     ##  ##     ##  ##  ##      ##   ##         ##     ##   ##   ##   ##     ##  
##         ##     ##  ##     ##  #####       ##   ######     ########   ##     ##  ########   
##         ##     ##  ##     ##  ##  ##      ##   ##         ##     ##  #########  ##   ##    
##    ##   ##     ##  ##     ##  ##   ##     ##   ##         ##     ##  ##     ##  ##    ##   
 ######     #######    #######   ##    ##   ####  ########   ########   ##     ##  ##     ##  
*/
#cookie-bar {
	background-color: rgba($color-1, 0.95);

	&.fixed {
		top: auto;
		bottom: 0;
	}

	p {
		padding: 8px 0;

		color: $color-text-light;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	a {
		font-family: $font-bold;
		@include font-size(11);
		text-transform: uppercase;
		line-height: 1.4em;

		padding-top: 1px;
	}

	a.cb-enable,
	a.cb-disable {
		background-color: lighten($color-1-lighten, 18%);

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			background-color: lighten($color-1-lighten, 12%);
		}
	}
}

/* 
########      ###     ##    ##   ##    ##   ########   ########   
##     ##    ## ##    ###   ##   ###   ##   ##         ##     ##  
##     ##   ##   ##   ####  ##   ####  ##   ##         ##     ##  
########   ##     ##  ## ## ##   ## ## ##   ######     ########   
##     ##  #########  ##  ####   ##  ####   ##         ##   ##    
##     ##  ##     ##  ##   ###   ##   ###   ##         ##    ##   
########   ##     ##  ##    ##   ##    ##   ########   ##     ##  
*/
.banner-top {
	width: 100%;
	height: 140px;
	position: relative;

	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;

	background-image: url('#{$image-path}banner/banner-default.jpg');
	background-size: cover;

	@include mq($from: tablet) {
		height: 180px;
	}

	@include mq($from: desktop) {
		height: 220px;
	}

	@include mq($from: wide) {
		height: 280px;
	}

	@include mq($from: xwide) {
		height: 300px;
	}

	.concept & {
		background-image: url('#{$image-path}banner/banner-concept.jpg');
		background-size: cover;
	}

	.signup-annonce &,
	.annonce-liste &,
	.annonce-detail & {
		background-image: url('#{$image-path}banner/banner-annonce.jpg');
		background-size: cover;
	}

	.signup-expertise &,
	.expertise-liste &,
	.expertise-detail &,
	.expert-liste &,
	.expert-detail & {
		background-image: url('#{$image-path}banner/banner-expert.jpg');
		background-size: cover;
	}

	.signup-galerie &,
	.galerie-liste &,
	.galerie-detail & {
		background-image: url('#{$image-path}banner/banner-expose.jpg');
		background-size: cover;
	}

	.evenement-liste &,
	.evenement-detail & {
		background-image: url('#{$image-path}banner/banner-evenement.jpg');
		background-size: cover;
	}

	.actualite-liste &,
	.actualite-detail & {
		background-image: url('#{$image-path}banner/banner-actu.jpg');
		background-size: cover;
	}

	.signup-collection & {
		background-image: url('#{$image-path}banner/banner-collection.jpg');
		background-size: cover;
	}

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba($color-1, 0.65);
	}

	&:after {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba($white, 0.1);
	}
}

.banner-top--collection {
	height: 200px;

	@include mq($from: tablet) {
		height: 220px;
	}

	@include mq($from: desktop) {
		height: 260px;
	}

	@include mq($from: wide) {
		height: 300px;
	}

	@include mq($from: xwide) {
		height: 320px;
	}
}


/* 
   ###     ########   ####     ###     ##    ##   ########   
  ## ##    ##     ##   ##     ## ##    ###   ##   ##         
 ##   ##   ##     ##   ##    ##   ##   ####  ##   ##         
##     ##  ########    ##   ##     ##  ## ## ##   ######     
#########  ##   ##     ##   #########  ##  ####   ##         
##     ##  ##    ##    ##   ##     ##  ##   ###   ##         
##     ##  ##     ##  ####  ##     ##  ##    ##   ########   
*/
.breadcrumbs {
	background-color: $color-bg;
	border-bottom: 1px solid $color-bg-darken;
	border-color: rgba($color-bg-darken, 0.6);

	.wrapper {
		padding: 8px 15px 10px 15px;

		@include mq($from: tablet) {
			padding: 8px 0 10px 0;
		}
	}

	ul {
		margin: 0;

		li {
			position: relative;
			display: inline-block;
			width: auto;
			padding: 0 13px 0 0;
			margin: 0;

			&:after {
				position: absolute;
				right: 0px;
				bottom: 0;
				margin: 0 0 2px 0;

				font-family: $font-icon;
				content: "\e910";
				color: lighten($color-1-lighten, 20%);
				@include font-size(10);
			}

			&:last-of-type:after {
				content: "";
			}

			a {
				font-family: $font-alt;
				color: lighten($color-1-lighten, 20%);
				@include font-size(13);

				border: none;

				transition: color 0.2s ease-in-out;

				.no-touch & {
					&:hover {
						color: $color-1-darken;
					}
				}
			}

			&:last-of-type a {
				color: $color-1-darken;
			}
		}
	}
}


/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/

/***** SLIDER *****/
.slider--container {
	position: relative;
	overflow: hidden;

	.observer {
		display: block;
		z-index: 10;
		width: 100px;
		height: 416px;

		position: absolute;
		bottom: -55%;
		left: 50%;
		margin-left: -50px;

		background: transparent url('#{$image-path}slider/observer.png') 0 0 no-repeat;
		background-size: 100px 416px;

		@include mq($from: tablet) {
			width: 120px;
			height: 499px;
			background-size: 120px 499px;

			margin-left: -60px;
		}

		@include mq($from: desktop) {
			width: 178px;
			height: 740px;
			background-size: 178px 740px;

			bottom: -45%;
			margin-left: -89px;
		}

		@include mq($from: wide) {
			bottom: -32%;
		}

		@include mq($from: xwide) {
			bottom: -35%;
		}
	}
}

.slider-home {
	position: relative;
	z-index: 1;
	height: 300px;
	width: 100%;
	background: $color-1-lighten;

	@include mq($from: tablet) {
		height: 360px;
	}

	@include mq($from: desktop) {
		height: 560px;
	}

	@include mq($from: wide) {
		height: 620px;
	}

	.owl-stage-outer,
	.owl-stage,
	.owl-item {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.item {
		height: 100%;
	}
}

/***** ENCART SEARCH *****/
.block-search {
	display: block;
	z-index: 15;
	width: 100%;
	height: auto;
	padding: 20px;
	background-color: rgba($color-1, 0.85);

	position: absolute;
	bottom: 0;

	text-align: center;

	@include mq($from: tablet) {
		bottom: auto;

		width: 340px;
		// height: 152px;

		top: 50%;
		margin-top: -76px;
		left: 50%;
		margin-left: -170px;
	}

	@include mq($from: desktop) {
		width: 480px;
		// height: 192px;

		margin-left: -240px;
		margin-top: -96px;
	}

	@include mq($from: wide) {
		width: 560px;
		// height: 224px;

		margin-left: -280px;
		margin-top: -112px;
	}

	@include mq($from: xwide) {
		width: 600px;
		// height: 244px;

		margin-left: -300px;
		margin-top: -122px;
	}

	.main-title {
		max-width: 260px;
		margin: 0 auto 0 auto;

		font-family: $font-bold;
		color: $color-text-light;
		text-transform: uppercase;
		line-height: 1.4em;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		@include mq($from: desktop) {
			max-width: 90%;
			margin: 0.25em auto 0.25em auto;

			@include font-size(18);
			letter-spacing: 0.05em;
		}

		@include mq($from: wide) {
			max-width: 95%;
			margin: 0.4em auto 0.4em auto;

			@include font-size(22);
		}

		@include mq($from: xwide) {
			margin: 0.3em auto 0.3em auto;

			@include font-size(25);
		}
	}
}

.input-search {
	position: relative;
	z-index: 1;
	overflow: hidden;

	display: inline-block;
	max-width: 350px;
	width: 220px;
	height: 36px;
	margin: 0 auto 5px auto;
	background: $white;
	box-shadow: inset 0px 0px 5px 0px rgba(0,0,0, 0.5);

	vertical-align: top;

	@include mq($from: desktop) {
		height: 40px;
		width: 240px;
		margin: 0 auto 10px auto;
	}

	@include mq($from: wide) {
		height: 46px;
		width: 280px;
		margin: 0 auto 12px auto;

		box-shadow: inset 0px 0px 7px 0px rgba(0,0,0, 0.5);
	}

	@include mq($from: xwide) {
		height: 50px;
		width: 320px;
		margin: 0 auto 16px auto;
	}

	&.input-search--page {
		box-shadow: none;
		border: 1px solid $color-1-lighten;
		border-color: rgba($color-1-lighten, 0.6);

		margin: 20px 0 0 0;

		@include mq($from: tablet) {
			margin: 40px 0 0 0;
		}

		@include mq($from: wide) {
			max-width: 70%;
		}
	}
}

.input-search__field {
	position: relative;
	display: block;
	width: 100%;
	height: 95%;
	background: transparent;
	float: right;
	z-index: 10;

	@include input_mobile;
	padding: 0 10px;
	border: none;
	border-radius: 0;

	color: $color-text;
	@include font-size(13);

	-webkit-appearance: none; /* for box shadows to show on iOS */

	@include mq($from: wide) {
		padding: 0 14px;
	}

	&:focus {
		outline: none;
	}
}

.input-search__label {
	display: inline-block;
	width: 100%;
	height: 36px;
	line-height: 36px;
	position: absolute;
	float: right;
	padding: 0 10px;

	text-align: left;
	pointer-events: none;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@include mq($from: desktop) {
		height: 40px;
		line-height: 40px;
	}

	@include mq($from: wide) {
		height: 46px;
		line-height: 46px;
		padding: 0 14px;
	}

	@include mq($from: xwide) {
		height: 50px;
		line-height: 50px;
	}

	&:before {
		position: absolute;
		width: 36px;
		height: 36px;
		top: 0;
		right: 0;

		font-family: $font-icon;
		content: "\e905";
		@include font-size(18);
		color: $color-text;
		text-align: center;

		transition: transform 0.1s cubic-bezier(0.7,0,0.3,1), color 0.4s ease-in-out, background-color 0.1s ease-in-out;

		@include mq($from: desktop) {
			width: 40px;
			height: 40px;

			@include font-size(20);
		}

		@include mq($from: wide) {
			width: 46px;
			height: 46px;

			@include font-size(22);
		}

		@include mq($from: wide) {
			width: 50px;
			height: 50px;

			@include font-size(24);
		}
	}
}

.input-search__label-content {
	display: block;
	width: 100%;
	position: relative;

	font-family: $font-alt;
	@include font-size(13);
	color: $color-text;

	transition: color 0.1s ease-in-out;

	@include mq($from: xwide) {
		@include font-size(14);
	}
}

.input-search__field:focus + .input-search__label::before,
.input-search--filled .input-search__label::before {
	transform: scale3d(38, 38, 1);
	color: $color-bg-darken;
	background-color: $color-bg-darken;

	transition: transform 0.4s cubic-bezier(0.7,0,0.3,1), color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.input-search__field:focus + .input-search__label-content,
.input-search--filled .input-search__label-content {
	color: $color-bg-darken;

	transition: color 0.3s ease-in-out;
}

/***** CATÉGORIES *****/
.category-container {
	position: relative;
	padding: 50px 15px 15px 15px;
	margin: 35px auto 0 auto;
	border: 1px solid $color-1;

	text-align: center;

	@include mq($from: tablet) {
		padding: 65px 15px 15px 15px;
		margin: 60px auto 0 auto;
	}

	@include mq($from: desktop) {
		padding: 55px 15px 15px 15px;
	}

	@include mq($from: wide) {
		padding: 60px 15px 15px 15px;
	}

	@include mq($from: xwide) {
		padding: 70px 15px 15px 15px;
		margin: 70px auto 0 auto;
	}

	&:before {
		display: block;
		content: "";

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		border: 6px solid $color-bg-darken;
		border-color: rgba($color-bg-darken, 0.6);

		transition: border-width 0.3s ease-in-out, border-color 0.3s ease-in-out;

		@include mq($from: tablet) {
			border-width: 7px;
		}
	}

	&:after {
		display: block;
		content: "";

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		border: 1px solid rgba($color-bg-darken, 0.35);

		transition: border-color 0.2s ease-in-out;
	}
}

.category__icon {
	width: 80px;
	height: 80px;

	position: absolute;
	top: 0;
	left: 50%;
	margin-top: -40px;
	margin-left: -40px;

	@include mq($from: tablet) {
		width: 100px;
		height: 100px;

		margin-top: -50px;
		margin-left: -50px;
	}

	@include mq($from: desktop) {
		width: 90px;
		height: 90px;

		margin-top: -45px;
		margin-left: -45px;
	}

	@include mq($from: xwide) {
		width: 100px;
		height: 100px;

		margin-top: -50px;
		margin-left: -50px;
	}

	.hexagon-container {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		.hexagon {
			width: 100%;
			height: 100%;
			fill: $white;
			stroke: $color-1;
			stroke-width: 0.8;

			transition: stroke-width 0.2s ease-in-out;
		}
	}

	.icon-cat {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;

		@include font-size(48);
		padding: 18% 0 0 0;

		vertical-align: middle;

		@include mq($from: tablet) {
			@include font-size(58);
			padding: 20% 0 0 0;
		}

		@include mq($from: desktop) {
			@include font-size(56);
			padding: 18% 0 0 0;
		}

		@include mq($from: xwide) {
			@include font-size(58);
			padding: 20% 0 0 0;
		}
	}
}

.category__title {
	margin: 0 auto;

	font-family: $font-semibold;
	@include font-size(14);
	text-transform: uppercase;
	color: $color-text;

	@include mq($from: tablet) {
		max-width: 80%;

		@include font-size(15);
		line-height: 1.4em;
	}

	@include mq($from: desktop) {
		max-width: 90%;

		@include font-size(14);
		line-height: 1.3em;
	}

	@include mq($from: wide) {
		max-width: 85%;

		@include font-size(15);
		line-height: 1.4em;
	}

	@include mq($from: xwide) {
		max-width: 75%;
	}
}

.category__link {
	display: inline-block;
	color: $color-text;
	margin: 0.7em auto 0.5em auto;

	@include font-size(15);

	@include mq($from: tablet) {
		margin: 0.7em auto 0.8em auto;
	}

	.icon-plus {
		display: block;
		transition: transform 0.25s ease-in-out;
	}
}

.category-container {

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		&:before {
			border-width: 11px;
			border-color: rgba($color-bg-darken, 0.35);
			
			@include mq($from: tablet) {
				border-width: 14px;
			}
		}

		&:after {
			border-color: $color-1;
		}

		.category__icon .hexagon-container .hexagon {
			stroke-width: 1.5;

			@include mq($from: tablet) {
				stroke-width: 1.4;
			}
		}

		.category__link .icon-plus {
			transform: rotate(270deg);
		}
	}
}

/***** PROFILS *****/
.profile-container {
	position: relative;
	padding-top: 100%;
	overflow: hidden;

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		background-color: transparent;
		background-position: center center;
		background-repeat: no-repeat;

		transform: scale(1);

		transition: transform 0.4s ease-out;
	}

	&.profile-container--exposant:before {
		background-image: url('#{$image-path}profile-exposant.jpg');
		background-size: cover;

		@include retina {
			background-image: url('#{$image-path}profile-exposant@2x.jpg');
			background-size: cover;
		}
	}

	&.profile-container--acheteur:before {
		background-image: url('#{$image-path}profile-acheteur.jpg');
		background-size: cover;

		@include retina {
			background-image: url('#{$image-path}profile-acheteur@2x.jpg');
			background-size: cover;
		}
	}

	&.profile-container--expert:before {
		background-image: url('#{$image-path}profile-expert.jpg');
		background-size: cover;

		@include retina {
			background-image: url('#{$image-path}profile-expert@2x.jpg');
			background-size: cover;
		}
	}

	&.profile-container--galerie:before {
		background-image: url('#{$image-path}profile-galerie.jpg');
		background-size: cover;

		@include retina {
			background-image: url('#{$image-path}profile-galerie@2x.jpg');
			background-size: cover;
		}
	}

	&:after {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba($white, 0.1);
	}
}

.profile-content {
	width: 100%;
	height: 100%;
	background-color: rgba($color-1, 0.6);

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	
	text-align: center;

	transition: background-color 0.4s ease-in-out;
}

.profile__title--container {
	width: 100%;
	@include center;
}

.profile__title {
	max-width: 80%;
	margin: 0 auto 15px auto;

	font-family: $font-extrabold;
	text-transform: uppercase;
	@include font-size(18);
	letter-spacing: 0.2em;
	color: $color-text-light;
	line-height: 1.6em;

	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

	@include mq($from: mobileLandscape) {
		@include font-size(16);
	}

	@include mq($from: tablet) {
		max-width: 70%;
		@include font-size(18);
	}

	@include mq($from: desktop) {
		max-width: 80%;
		margin: 0 auto 10px auto;
		@include font-size(15);
		line-height: 1.4em;
	}

	@include mq($from: wide) {
		margin: 0 auto 12px auto;
		@include font-size(16);
		line-height: 1.5em;
	}

	@include mq($from: xwide) {
		margin: 0 auto 15px auto;
		@include font-size(17);
		line-height: 1.6em;
	}

	.no-touch & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&:after {
		display: block;
		content: "";
		width: 15px;
		height: 1px;
		background-color: $white;
		margin: 15px auto 0 auto;

		@include mq($from: desktop) {
			margin: 10px auto 0 auto;
		}

		@include mq($from: wide) {
			margin: 12px auto 0 auto;
		}

		@include mq($from: xwide) {
			margin: 15px auto 0 auto;
		}
	}
}

.profile__subtitle {
	font-family: $font-alt;
	color: $color-text-light;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.profile-container {

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		&:before {
			transform: scale(1.1);
			transition: transform 2.4s linear;
		}

		.profile-content {
			background-color: rgba($color-1, 0.8);
			transition: background-color 0.8s ease-in-out;
		}
	}
}


/* 
 ######     #######   ##    ##    ######    ########   ########   ########   
##    ##   ##     ##  ###   ##   ##    ##   ##         ##     ##     ##      
##         ##     ##  ####  ##   ##         ##         ##     ##     ##      
##         ##     ##  ## ## ##   ##         ######     ########      ##      
##         ##     ##  ##  ####   ##         ##         ##            ##      
##    ##   ##     ##  ##   ###   ##    ##   ##         ##            ##      
 ######     #######   ##    ##    ######    ########   ##            ##      
*/
.concept--lang-list {
	text-align: center;
	margin: 1em auto;

	@include mq($from: tablet) {
		margin: 1em auto 1.5em auto;
	}

	@include mq($from: desktop) {
		margin: 1em auto 2em auto;
	}

	li {
		display: inline-block;
		height: 100%;
		
		a {
			display: block;
			width: 30px;
			height: 30px;
			line-height: 28px;
			border: 2px solid $color-1-lighten;
			border-color: $color-bg-darken;

			@include font-size(11);
			text-transform: uppercase;
			color: lighten($color-1-lighten, 20%);

			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				border-color: lighten($color-1-lighten, 15%);
				color: $color-text;
			}

			&.current {
				color: $color-text;
				background-color: $color-bg-darken;
			}
		}
	}
}


/* 
 ######     #######   ##         ##         ########    ######    ########   ####   #######   ##    ##   
##    ##   ##     ##  ##         ##         ##         ##    ##      ##       ##   ##     ##  ###   ##   
##         ##     ##  ##         ##         ##         ##            ##       ##   ##     ##  ####  ##   
##         ##     ##  ##         ##         ######     ##            ##       ##   ##     ##  ## ## ##   
##         ##     ##  ##         ##         ##         ##            ##       ##   ##     ##  ##  ####   
##    ##   ##     ##  ##         ##         ##         ##    ##      ##       ##   ##     ##  ##   ###   
 ######     #######   ########   ########   ########    ######       ##      ####   #######   ##    ##   
*/

/***** SIDEBAR [Filters] *****/
label.category-filter--label {
	position: relative;
	display: block;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	padding: 0 10px;

	color: $color-text;
	background-color: lighten($color-bg-darken, 6%);

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:after {
		font-family: $font-icon;
		content: "\e910";
		@include font-size(12);
		color: $color-text;

		position: absolute;
		top: 0;
		right: 10px;

		transition: transform 0.2s ease-in-out;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-1-darken;
		background-color: $color-bg-darken;
	}
}

input.category-filter--input[type=checkbox] {
	position: absolute;
	opacity: 0;

	&:checked + label + ul.collection--category-filters,
	&:checked + label:nth-of-type(n) + ul.collection--category-filters {
		display: block;
	}

	&:checked + label {
		color: $color-1-darken;
		background-color: $color-bg-darken;

		&:after {
			transform: rotate(90deg);
		}
	}
}

.collection--category-filters {
	@include mq($until: tablet) {
		display: none;
		margin: 0;
	}
	
	@include mq($from: tablet) {
		max-width: 90%;
		margin-top: 1.8em;
	}

	@include mq($from: desktop) {
		max-width: 85%;
	}

	@include mq($from: xwide) {
		max-width: 90%;
	}
}

.collection--category__item {
	overflow: hidden;
	transition: background-color 0.2s ease-in-out;

	@include mq($until: tablet) {
		border-bottom: 1px solid $color-bg-darken;
		margin: 0;
		padding: 6px 10px;
	}

	@include mq($from: tablet) {
		margin: 0 0 1.2em 0;
	}
}

.collection--category__link {
	@include clearfix;
	display: block;
	width: auto;
	height: 30px;

	font-family: $font-base;
	@include font-size(12);
	text-transform: uppercase;

	transition: font-family 0.2s ease-in-out;

	@include mq($from: tablet) {
		height: 44px;
	}

	span {
		width: auto;

		&.link-icon--container {
			position: relative;
			display: block;
			float: left;
			width: 44px;
			height: 44px;
			margin: 0 4px 0 0;

			.link-icon {
				position: relative;
				z-index: 2;
				
				@include center;
				@include font-size(32);

				&.icon-frame {
					@include font-size(29);
				}
			}

			.hexagon-container {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				.hexagon {
					width: 100%;
					height: 100%;
					fill: $white;
					stroke: $color-1;
					stroke-width: 1.5;

					transition: stroke-width 0.2s ease-in-out;
				}
			}
		}

		&.link-text {

			@include mq($from: tablet) {
				max-width: 80%;
			}
		}
	}
}

.collection--category__item {
	
	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active,
	&.current {

		@include mq($until: tablet) {
			background-color: lighten($color-bg-darken, 7%);
		}

		.collection--category__link {

			@include mq($until: tablet) {
				font-family: $font-semibold;
			}

			.link-icon--container .hexagon-container .hexagon {

				@include mq($from: tablet) {
					animation: dash 0.5s ease-in forwards;
				}
			}
		}
	}
}

.collection--exhibition-filters {

	@include mq($from: tablet) {
		margin: 60px 0 0 0;
	}
}

.collection--exhibition-filter__item {
	display: inline-block;
	margin: 0 2px 6px 0;

	@include mq($from: tablet) {
		display: block;
	}
	
	a {
		display: inline-block;
		box-sizing: content-box;
		// height: 30px;
		height: auto;
		line-height: 1.25em;
		// line-height: 30px;
		padding: 4px 8px;
		border: 1px solid $color-1-lighten;
		border-color: rgba($color-1-lighten, 0.6);

		font-family: $font-alt;
		@include font-size(14);

		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

		@include mq($from: tablet) {
			// height: 26px;
			// line-height: 26px;
			padding: 4px 10px;

			@include font-size(13);
		}

		@include mq($from: wide) {
			// height: 28px;
			// line-height: 28px;
			@include font-size(14);
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			background-color: $color-1-lighten;
			color: $color-text-light;
		}
	}

	&.current {
		
		a {
			background-color: $color-1-lighten;
			color: $color-text-light;
		}
	}
}

.collection--exhibition-filter__item--inner {

	@include mq($from: tablet) {
		margin-left: 10px;
	}
}


/***** LINK DÉPÔT *****/
.link-depot--container--global {
	@include clearfix;

	.link-depot--container--left {
	
		@include mq($until: tablet) {
			margin-bottom: 10px;
		}
	}
}

.link-depot--container {

	@include mq($from: tablet) {
		text-align: right;
		float: right;
	}

	&.link-depot--container--left {
		text-align: left;

		@include mq($from: tablet) {
			float: left;
		}
	}

	.main-link {
		margin-bottom: 4px;
	}
}

.link-depot {
	display: inline-block;
	height: 36px;
	line-height: 37px;
	position: relative;

	background-color: $color-1;
	padding: 0 25px 0 15px;

	font-family: $font-light;
	@include font-size(11);
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: $color-text-light;

	transition: background-color 0.2s ease-in-out;

	@include mq($from: tablet) {
		padding: 0 28px 0 20px;
	}

	@include mq($from: wide) {
		height: 40px;
		line-height: 41px;
	}

	&:after {
		font-family: $font-icon;
		content: "\e910";
		letter-spacing: 0.1em;
		@include font-size(8);

		position: absolute;
		top: 0;
		right: 13px;

		transition: right 0.2s ease-in-out;

		@include mq($from: tablet) {
			right: 15px;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-text-light;
		background-color: $color-1-darken;

		&:after {
			right: 6px;

			@include mq($from: tablet) {
				right: 8px;
			}
		}
	}
}

/***** MAIN CONTENT *****/
.expert-list,
.collection-list {
	@include clearfix;
	width: 100%;
	margin-top: 2em;

	.galerie-detail &,
	.evenement-detail & {

		margin-top: 20px;

		@include mq($from: wide) {
			margin-top: 24px;
		}

		@include mq($from: xwide) {
			margin-top: 30px;
		}
	}
}

.expert__item--container,
.collection__item--container {
	margin: 0 auto 20px auto;

	&:last-of-type {
		margin: 0;
	}

	@include mq($from: mobileLandscape) {
		margin: 0;
		padding: 0 5px 10px 5px;
		width: percentage(1/2);
		float: left;

		&:nth-of-type(odd) {
			padding: 0 5px 10px 0;
		}

		&:nth-of-type(even) {
			padding: 0 0 10px 5px;
		}
	}

	@include mq($from: desktop) {
		width: percentage(1/3);

		&:nth-of-type(3n-2) {
			padding: 0 5px 10px 0;
		}

		&:nth-of-type(3n-1) {
			padding: 0 5px 10px 5px;
		}

		&:nth-of-type(3n) {
			padding: 0 0 10px 5px;
		}
	}

	@include mq($from: wide) {
		width: percentage(1/4);

		&:nth-of-type(4n-3) {
			padding: 0 5px 10px 0;
		}

		&:nth-of-type(4n-2),
		&:nth-of-type(4n-1) {
			padding: 0 5px 10px 5px;
		}

		&:nth-of-type(4n) {
			padding: 0 0 10px 5px;
		}
	}

	@include mq($from: xwide) {
		width: percentage(1/5);

		&:nth-of-type(5n-4) {
			padding: 0 5px 10px 0;
		}

		&:nth-of-type(5n-3),
		&:nth-of-type(5n-2),
		&:nth-of-type(5n-1) {
			padding: 0 5px 10px 5px;
		}

		&:nth-of-type(5n) {
			padding: 0 0 10px 5px;
		}
	}

	.masonry & {
		padding: 0;
		margin-bottom: 10px;

		@include mq($from: mobileLandscape) {
			width: calc(50% - 10px);
		}

		@include mq($from: wide) {
			width: calc(33.33% - 10px);
		}
	}

	&.actu__item--container {

		@include mq($from: mobileLandscape) {
			margin: 0;
			width: percentage(1/2);
			float: left;

			&:nth-of-type(odd) {
				padding: 0 8px 16px 0;
			}

			&:nth-of-type(even) {
				padding: 0 0 16px 8px;
			}
		}

		@include mq($from: tablet) {
			width: percentage(1/3);
			
			&:nth-of-type(3n-2) {
				padding: 0 6px 12px 0;
			}

			&:nth-of-type(3n-1) {
				padding: 0 6px 12px 6px;
			}

			&:nth-of-type(3n) {
				padding: 0 0 12px 6px;
			}
		}

		@include mq($from: desktop) {
			width: percentage(1/4);

			&:nth-of-type(4n-3) {
				padding: 0 6px 12px 0;
			}

			&:nth-of-type(4n-2),
			&:nth-of-type(4n-1) {
				padding: 0 6px 12px 6px;
			}

			&:nth-of-type(4n) {
				padding: 0 0 12px 6px;
			}
		}

		@include mq($from: wide) {
			width: percentage(1/5);

			&:nth-of-type(5n-4) {
				padding: 0 6px 12px 0;
			}

			&:nth-of-type(5n-3),
			&:nth-of-type(5n-2),
			&:nth-of-type(5n-1) {
				padding: 0 6px 12px 6px;
			}

			&:nth-of-type(5n) {
				padding: 0 0 12px 6px;
			}
		}

		@include mq($from: xwide) {
			width: percentage(1/6);

			&:nth-of-type(6n-5) {
				padding: 0 6px 12px 0;
			}

			&:nth-of-type(6n-4),
			&:nth-of-type(6n-3),
			&:nth-of-type(6n-2),
			&:nth-of-type(6n-1) {
				padding: 0 6px 12px 6px;
			}

			&:nth-of-type(6n) {
				padding: 0 0 12px 6px;
			}
		}
	}
}

// .expert__item--container {
// 	&:nth-of-type(3),
// 	&:nth-of-type(4),
// 	&:nth-of-type(5) {
// 		display: none;
// 	}
	
// 	@include mq($from: desktop) {
// 		&:nth-of-type(3) {
// 			display: block;
// 		}
// 	}

// 	@include mq($from: wide) {
// 		&:nth-of-type(4) {
// 			display: block;
// 		}
// 	}

// 	@include mq($from: xwide) {
// 		&:nth-of-type(5) {
// 			display: block;
// 		}
// 	}
// }

.expert__item--container--intro {

	.expert__item {
		background-color: $color-bg-darken;

		.main-title {
			margin-bottom: 1em;
		}
	}
}

.expert__item {
	position: relative;
	padding: 30px;
	border: 1px solid $color-1;
	z-index: 2;

	text-align: center;

	@include mq($from: mobileLandscape) {
		padding: 30px 20px;
	}

	@include mq($from: tablet) {
		padding: 25px 20px 30px 20px;
	}

	@include mq($from: wide) {
		padding: 20px 20px 25px 20px;
	}

	&:before {
		display: block;
		content: "";
		z-index: -1;

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		border: 6px solid $color-bg-darken;
		border-color: rgba($color-bg-darken, 0.6);

		transition: border-width 0.3s ease-in-out, border-color 0.3s ease-in-out;

		@include mq($from: tablet) {
			border-width: 7px;
		}
	}

	&:after {
		display: block;
		content: "";
		z-index: -1;

		width: 100%;
		height: 100%;


		position: absolute;
		top: 0;
		left: 0;

		border: 1px solid rgba($color-bg-darken, 0.35);

		transition: border-color 0.2s ease-in-out;
	}
}

.expertise__title,
.expert__title {
	margin: 0 0 1.5em 0;

	@include mq($from: tablet) {
		margin: 0.5em 0 1.5em 0;
	}

	@include mq($from: wide) {
		margin: 0.5em 0 1.6em 0;
	}

	span {
		display: block;

		&.main-title {
			margin: 0 0 1em 0;

			font-family: $font-semibold;
			@include font-size(15);
			text-transform: uppercase;
			letter-spacing: 0.05em;
			color: $color-text;

			@include mq($from: mobileLandscape) {
				@include font-size(14);
				line-height: 1.3em;
			}

			&:after {
				display: block;
				content: "";
				width: 35px;
				height: 1px;
				background-color: $color-1;
				margin: 6px auto 0 auto;

				@include mq($from: tablet) {
					width: 40px;
				}

				@include mq($from: desktop) {
					width: 35px;
				}

				@include mq($from: wide) {
					width: 40px;
				}
			}
		}

		&.alt-title {
			font-family: $font-alt;
			@include font-size(13);
			color: $color-text;

			@include mq($from: tablet) {
				@include font-size(15);
			}

			@include mq($from: desktop) {
				@include font-size(13);
			}

			@include mq($from: wide) {
				@include font-size(14);
			}

			&.alt-title--activite {
				color: lighten($color-1-lighten, 15%);

				&:before {
					display: block;
					content: "";
					width: 16px;
					height: 1px;
					background-color: lighten($color-1-lighten, 25%);
					margin: 0 auto 12px auto;
				}
			}

			.collection-liste & {
				margin-top: 20px;
			}
		}
	}

	.expertise__title--category {

		.icon {
			@include font-size(26);
		}
	}

	.expert__title--localisation {
		position: relative;
		display: inline-block;
		padding-left: 20px;
		margin-top: 1em;

		.icon {
			position: absolute;
			top: -2px;
			left: 0;

			@include font-size(18);
		}
	}
}

.expert__content {
	margin: 0 0 1.8em 0 !important;

	@include font-size(13);

	@include mq($from: wide) {
		margin: 0 0 2.2em 0 !important;
	}
}

.expert__name {
	margin: 0 0 1.8em 0 !important;

	font-family: $font-alt;
	@include font-size(13);
	text-transform: capitalize;

	@include mq($from: desktop) {
		@include font-size(14);
	}

	@include mq($from: wide) {
		margin: 0 0 2em 0 !important;
		@include font-size(13);
	}
}

.expert__link {
	display: inline-block;
	padding: 10px 12px;
	border: 1px solid $color-1-lighten;
	border-color: rgba($color-1-lighten, 0.5);

	font-family: $font-semibold;
	@include font-size(11);
	color: $color-text;
	text-transform: uppercase;
	letter-spacing: 0.05em;

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	@include mq($from: wide) {
		padding: 10px;
		letter-spacing: 0.02em;
	}
}

.expert__item {

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		&:before {
			border-width: 11px;
			border-color: rgba($color-bg-darken, 0.35);
			
			@include mq($from: tablet) {
				border-width: 14px;
			}
		}

		&:after {
			border-color: $color-1;
		}

		.expert__link {
			background-color: $color-1;
			color: $color-text-light;
		}
	}
}

.collection__item {
	overflow: hidden;
	position: relative;
	background-color: lighten($color-bg-darken, 4%);

	text-align: center;

	&.vente,
	&.partielle {

		&:after {
			display: block;
			height: 24px;
			line-height: 22px;

			position: absolute;
			top: 20px;
			left: -25px;

			content: "En vente";
			font-family: $font-alt;
			@include font-size(12);
			color: $color-text-light;

			-webkit-font-smoothing: antialiased;
    		-moz-osx-font-smoothing: grayscale;

			background-color: $color-1;
			padding: 0 60px;

			transform: rotate(-30deg);
		}
	}

	&.partielle {

		&:after {
			content: "En vente partielle";
			top: 25px;
			left: -45px;
		}
	}
}

.collection__item--video {
	height: 100%;
	background-color: $color-1-darken;

	.video-container {
		position: relative;

		&:before {
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			background-color: $color-1-lighten;

			position: absolute;
			top: 0;
			left: 0;

			opacity: 0.75;

			transition: opacity 0.2s ease-in-out;
		}

		&:after {
			display: block;
			font-family: $font-icon;
			content: "\e917";
			color: $white;

			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			width: 52px;
			height: 52px;
			line-height: 50px;
			@include font-size(48);
			text-align: center;

			position: absolute;
			top: 50%;
			margin-top: -25px;
			left: 50%;
			margin-left: -25px;
		}
		
		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			cursor: pointer;

			&:before {
				opacity: 0.9;
			}
		}
	}
}

.collection__image {
	position: relative;
	overflow: hidden;
	background-color: $color-1;

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		background-color: rgba($color-1-lighten, 0.9);

		transform: translateX(-100%);
		opacity: 0;

		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}

	&:after {
		display: block;
		width: auto;
		height: 36px;
		line-height: 36px;
		border: 1px solid rgba($white, 0.5);
		content: "Découvrir";
		padding: 0 20px;

		position: absolute;
		@include center;

		font-family: $font-base;
		@include font-size(11);
		text-transform: uppercase;
		letter-spacing: 0.1em;
		color: $color-text-light;

		opacity: 0;

		transition: opacity 0.3s ease-in-out;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.collection__item {

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;

		.collection__image {

			&:before {
				transform: translateX(0);
				opacity: 1;
			}

			&:after {
				opacity: 1;
				transition-delay: 0.1s;
			}
		}
	}
}

.collection__info {
	background-color: lighten($color-bg-darken, 4%);
	padding: 20px;

	@include mq($from: tablet) {
		padding: 20px 10px 25px 10px;
	}

	@include mq($from: xwide) {
		padding: 25px 10px 30px 10px;
	}

	.collection__title {
		max-width: 100%;
		margin-bottom: 0.35em;
		line-height: 1.1em;

		@include mq($from: tablet) {
			@include font-size(17);
		}

		@include mq($from: xwide) {
			@include font-size(18);
		}

		&:after {
			margin-top: 7px;
		}
	}

	.collection__categorie {
		margin-bottom: 0.8em;
	}

	.collection__localisation {
		position: relative;
		display: inline-block;
		padding: 0 0 0 11px;

		@include font-size(13);

		@include mq($from: wide) {
			padding: 0 0 0 12px;
		}

		.icon-pointer {
			position: absolute;
			top: -2px;
			left: -11px;

			@include font-size(18);

			@include mq($from: wide) {
				top: -3px;
				left: -12px;

				@include font-size(19);
			}
		}
	}
}

.pagination {
	width: 100%;
	margin: 20px 0 0 0;

	.multipage {
		text-align: right;
		
		li {
			display: inline-block;
			margin: 0;
			padding: 0;

			a {
				display: block;
				width: 26px;
				height: 26px;
				line-height: 23px;
				border: 1px solid $color-1;

				@include font-size(13);
				text-align: center;
				color: $color-1;

				transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

				.no-touch & {
					&:hover {
						color: $white;
						background-color: rgba($color-1, 0.8);
					}
				}
			}

			&.current a {
				color: $white;
				background-color: $color-1-lighten;
			}
		}
	}
}


/* 
 ######     #######   ##              ########   ########   ########      ###     ####  ##         
##    ##   ##     ##  ##              ##     ##  ##            ##        ## ##     ##   ##         
##         ##     ##  ##              ##     ##  ##            ##       ##   ##    ##   ##         
##         ##     ##  ##              ##     ##  ######        ##      ##     ##   ##   ##         
##         ##     ##  ##              ##     ##  ##            ##      #########   ##   ##         
##    ##   ##     ##  ##              ##     ##  ##            ##      ##     ##   ##   ##         
 ######     #######   ########        ########   ########      ##      ##     ##  ####  ########   
*/
/***** SIDEBAR *****/
.accordion-menu {

	@include mq($from: tablet) {
		padding-top: 40px;
	}
}

.collection-detail--container {

	@include mq($from: tablet) {
		max-width: 90%;
		margin-top: 2.5em;
	}

	@include mq($from: wide) {
		max-width: 85%;
	}

	@include mq($from: wide) {
		max-width: 80%;
	}

	.actualite-detail & {
		margin-top: 0;
	}
}

.collection-detail--header {
	margin: 0 0 20px 0;

	@include mq($from: tablet) {
		margin: 0 0 30px 0;
	}

	.title-lev3 {
		max-width: 100%;
		margin-bottom: 0.8em;
	}
}

.collection-detail--content {
	margin: 0 0 20px 0;

	@include mq($from: tablet) {
		margin: 0 0 30px 0;
	}

	.collection-detail & {
		margin: 0 0 28px 0;
		padding-bottom: 15px;
		border-bottom: 1px solid $color-bg-darken;
	}
}

.collection-detail--files {
	margin: 0 0 20px 0;

	li {
		margin: 0 0 0.6em 0;

		a {
			display: inline-block;
			position: relative;

			&:after {
				font-family: $font-icon;
				content: "\e910";
				@include font-size(11);

				position: absolute;
				top: 4px;
				right: -14px;

				transition: right 0.2s ease-in-out;
			}

			&:before {
				font-family: $font-icon;
				content: "\e916";
				@include font-size(11);

				margin: 0 6px 0 0;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				
				&:after {
					right: -20px;
				}
			}
		}
	}

	.expert-detail & {
		margin-top: 20px;
	}
}

.collection-detail--share-list {
	
	li {
		display: inline-block;
		line-height: 16px;
		vertical-align: bottom;

		&:first-of-type {
			margin-right: 4px;
		}

		span.title-share {
			font-family: $font-alt;
			@include font-size(13);

			&:before {
				display: inline-block;
				content: "";

				width: 14px;
				height: 1px;
				background-color: rgba($color-1-lighten, 0.4);

				margin: 0 5px 3px 0;
			}
		}

		a.icon-share {
			display: block;
			@include font-size(16);

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				color: lighten($color-1-lighten, 20%);
			}
		}
	}
}

.collection-detail__localisation,
.collection-detail__category {
	position: relative;
	padding-left: 20px;

	@include font-size(13);
	color: $color-1-lighten;

	.icon {
		position: absolute;
		top: -2px;
		left: -2px;

		@include font-size(18);
	}
}

.collection-detail__category {
	padding: 8px 0 10px 28px;
	margin: 0 0 1em 0;
	
	font-family: $font-alt;
	@include font-size(14);

	border-width: 1px 0 1px 0;
	border-style: solid;
	border-color: $color-bg-darken;

	.icon {
		position: absolute;
		top: 6px;
		left: -2px;

		@include font-size(26);
	}

	.collection-detail--header & {
		padding: 0 0 0 28px;
		border: none;
		margin: 0;

		.icon {
			top: -2px;
		}
	}
}

.collection-detail--contenu {
	font-family: $font-alt;
	@include font-size(15);
	color: $color-1-lighten;

	@include mq($from: tablet) {
		margin-top: 40px;
		padding-top: 20px;
		border-top: 1px solid $color-bg-darken;
	}
}

.collection-detail--detail__list {
	margin: 0 0 30px 0;
	
	li {
		margin: 0 0 0.4em 0;

		font-family: $font-alt;
			
		&:before {
			display: inline-block;
			content: "•";
			margin: 0 5px 0 0;
		}
	}
}

.collection-detail--desc__list {
	margin: 0 0 40px 0;
	
	li {
		margin: 0 0 0.4em 0;

		&:not(.desc__list--intro) {
			
			&:before {
				margin: 0 3px 0 0;

				font-family: $font-icon;
				content: "\e910";
				@include font-size(11);
			}
		}
	}
}

.collection-detail__item {
	cursor: default !important;

	&.vente {

		&:after {
			content: "à vendre";

			top: 20px;
			left: -25px;
		}
	}
}

.collection-detail__image {

	&:before {
		display: none;
	}

	&:after {
		display: none;
	}

	.lightgallery & {
		display: block;
		height: auto;
		background-color: $color-1;

		img {
			opacity: 1;
			transform: scale(1);

			transition: transform 0.2s linear, opacity 0.2s linear;
		}
		
		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			cursor: pointer;

			img {
				opacity: 0.85;
				transform: scale(1.1);

				transition: transform 3s linear, opacity 1.5s linear;
			}
		}
	}
}

.collection-detail__info {
	padding: 10px 15px 12px 15px;

	@include mq($from: tablet) {
		padding: 10px 10px 12px 10px;
	}
}

.collection-detail__title {
	margin: 0 !important;

	@include mq($from: tablet) {
		font-size: 15px !important;
		font-size: 1.5rem !important;
	}

	@include mq($from: desktop) {
		font-size: 16px !important;
		font-size: 1.6rem !important;
	}

	&:after {
		display: none;
	}
}




/* 
   ###     ##    ##   ##    ##    #######   ##    ##    ######    ########    ######    
  ## ##    ###   ##   ###   ##   ##     ##  ###   ##   ##    ##   ##         ##    ##   
 ##   ##   ####  ##   ####  ##   ##     ##  ####  ##   ##         ##         ##         
##     ##  ## ## ##   ## ## ##   ##     ##  ## ## ##   ##         ######      ######    
#########  ##  ####   ##  ####   ##     ##  ##  ####   ##         ##               ##   
##     ##  ##   ###   ##   ###   ##     ##  ##   ###   ##    ##   ##         ##    ##   
##     ##  ##    ##   ##    ##    #######   ##    ##    ######    ########    ######    
*/
.annonce__image {
	position: relative;
}

.annonce__date {
	width: 100%;
	height: 30px;
	line-height: 28px;
	background-color: $color-1;

	position: absolute;
	left: 0;
	bottom: 0;

	font-family: $font-alt;
	@include font-size(13);
	color: $color-text-light;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
	}
}

.annonce__info {
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 15px;

	@include mq($from: wide) {
		padding-right: 20px;
		padding-left: 20px;
		padding-bottom: 20px;
	}
}

.annonce__categorie {
	position: relative;
	display: block;
	margin: 0 auto;
	text-align: center;

	@include font-size(12);
	text-transform: uppercase;

	.annonce__categorie--icon {
		display: block;
		margin: 0 auto;

		@include font-size(24);

		@include mq($from: wide) {
			@include font-size(26);
		}
	}
}

.annonce__content {
	margin: 15px auto 5px auto !important;

	@include font-size(13);
}


/* 
   ###      ######    ########   ##     ##  
  ## ##    ##    ##      ##      ##     ##  
 ##   ##   ##            ##      ##     ##  
##     ##  ##            ##      ##     ##  
#########  ##            ##      ##     ##  
##     ##  ##    ##      ##      ##     ##  
##     ##   ######       ##       #######   
*/
/***** ACTU LISTE *****/
.actu-list--container {
	@include clearfix;

	@include mq($from: wide) {
		margin: -12px;
	}

	@include mq($from: xwide) {
		margin: -15px;
	}
}

.actu__image {

	&:after {
		content: "Lire l'actu";

		@include mq($from: tablet) {
			width: 55%;
		}
	}
}

/***** ACTU DETAIL *****/
.actu-detail--date {
	// font-family: $font-semibold;
	color: lighten($color-1-lighten, 10%);

	@include mq($from: tablet) {
		@include font-size(15);
	}
}


/* 
########   ##     ##  ########   ########   ########   ########   
##          ##   ##   ##     ##  ##         ##     ##     ##      
##           ## ##    ##     ##  ##         ##     ##     ##      
######        ###     ########   ######     ########      ##      
##           ## ##    ##         ##         ##   ##       ##      
##          ##   ##   ##         ##         ##    ##      ##      
########   ##     ##  ##         ########   ##     ##     ##      
*/
/**************************************/
/*********** JE SUIS EXPERT ***********/
/**************************************/
.page-content--expert {
	margin-bottom: 20px;

	@include mq($from: tablet) {
		margin-bottom: 40px;
	}

	@include mq($from: desktop) {
		margin-bottom: 60px;
	}
}

/***** FORM *****/
.contact-form,
.header-form,
.footer-form,
.command-steps,
.info {
	@include clearfix;
	margin: 0 auto;
	
	@include mq($from: tablet) {
		width: 75%;
	}

	@include mq($from: desktop) {
		width: 85%;
	}

	@include mq($from: wide) {
		width: 70%;
	}

	&.contact-form--fullwidth {
		width: 100%;
	}
}

.header-form {
	margin-bottom: 10px;
	padding: 10px 0;

	border-top: 1px solid $color-bg-darken;
	border-bottom: 1px solid $color-bg-darken;

	@include mq($from: desktop) {
		margin-bottom: 30px;
		padding: 15px 0;
	}
}

.header-form + .header-form {
	border-top: none;

	@include mq($from: desktop) {
		margin-top: -20px;
	}
}

.header-form__title {
	margin: 0 0 1em 0;

	font-family: $font-semibold;
	text-transform: uppercase;
	color: $color-1-lighten;
}

.header-form__content {
	line-height: 1.6em;

	.title {
		display: block;
		margin: 0 0 0.2em 0;

		font-family: $font-alt;
		@include font-size(16);
	}
}

.footer-form {
	margin-top: 20px;
	padding-top: 10px;

	border-top: 1px solid $color-bg-darken;

	@include mq($from: desktop) {
		margin-top: 30px;
		padding-top: 20px;
	}

	p {
		font-family: $font-semibold;
		text-transform: uppercase;
		color: $color-1-lighten;
	}
}

.info {
	margin-bottom: 20px; 
	padding: 10px;
	border: 1px solid;
	display: none;

	font-family: $font-semibold;

	&.info--adherent {
		width: 100%;
	}

	&.info--success {
		display: block;
		color: $color-success;
		border-color: $color-success;
		background-color: lighten($color-success, 55%);
	}

	&.info--error {
		display: block;
		color: $color-cancel;
		border-color: $color-cancel;
		background-color: lighten($color-cancel, 48%);
	}
}

.command-steps {
	margin: 0 auto 20px auto;

	@include mq($from: desktop) {
		margin: 0 auto 40px auto;
	}

	li {
		display: inline-block;
		float: left;
		margin: 0;
		padding: 0 2px;

		height: auto;
		width: percentage(1/3);

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		a {
			display: block;
			width: 100%;
			height: 36px;
			line-height: 36px;

			box-sizing: content-box;

			background-color: lighten($color-bg-darken, 5%);
			border: 1px solid lighten($color-bg-darken, 5%);

			text-align: center;
			color: lighten($color-1-lighten, 28%);

			transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(12);
			}

			@include mq($from: desktop) {
				height: 44px;
				line-height: 44px;

				@include font-size(14);
			}

			&.current {
				background-color: darken($color-bg-darken, 5%);
				border-color: darken($color-bg-darken, 5%);
				color: $color-1-darken;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				border-color: darken($color-bg-darken, 15%);
				color: lighten($color-1-lighten, 10%);
			}
		}
	}
}

.form__layout {
	width: 100%;
	margin: 0;

	@include mq($from: desktop) {
		width: percentage(1/2);
		float: left;
	}

	&.form__layout--left {
		margin: 10px 0 0 0;

		@include mq($from: desktop) {
			margin: 0;
			padding-right: 20px;
		}

		@include mq($from: wide) {
			padding-right: 30px;
		}
	}

	&.form__layout--right {
		@include mq($from: desktop) {
			padding-left: 20px;
		}

		@include mq($from: wide) {
			padding-left: 30px;
		}
	}

	&.form__layout--fullwidth {
		width: 100%;
		padding: 0;
	}

	&.form__layout--buttons {
		@include mq($from: desktop) {
			margin-top: 10px;
		}

		@include mq($from: xwide) {
			margin-top: 15px;
		}
	}

	li {
		display: block;
		margin-bottom: 10px;
	}

	.form__layout-inner {
		@include clearfix;
		margin: 0;
	}
}

.form__input-container {
	@include clearfix;
	position: relative;

	&:after {
		display: block;
		width: 15px;
		height: 15px;
		line-height: 15px;
		position: absolute;
		bottom: 17px;
		right: 5px;
		opacity: 0;
			
		background-color: $color-cancel;
		border-radius: 50%;

		text-align: center;
		content: "!";
		@include font-size(13);
		color: $color-text-light;

		pointer-events: none;

		transition: opacity 0.2s ease-in-out;
	}

	&.form__input-container--textarea:after {
		bottom: auto !important;
		top: 35px;
	}

	&.form__input-container--inner {
		width: 100%;
		float: left;

		border: 1px solid $color-1-lighten;
		border-color: rgba($color-1-lighten, 0.6);

		&.error {
			border-color: $color-cancel;
		}

		&:last-of-type {
			margin-bottom: 3px;
		}
	}

	&.form__input-container--smaller {
		float: left;
	}

	&.form__input-container--smaller--1-3 {
		width: 32.5%;
		width: calc(35% - 5px);
	}

	&.form__input-container--smaller--2-3 {
		float: right;
		width: 65%;
		width: calc(65% - 5px);
	}

	&.form__input-container--select {
		position: relative;

		&:before {
			display: block;
			line-height: 35px;

			position: absolute;
			right: 10px;
			bottom: 0;

			font-family: $font-icon;
			content: '\e910';
			color: $color-1;
			@include font-size(12);
			
			transform: rotate(90deg);

			pointer-events:none;	
		}

		select.select {
			position: relative;
			width: 100%;
			padding: 10px;

			border: 1px solid $color-1-lighten;
			border-color: rgba($color-1-lighten, 0.6);
			border-radius: 0;
			background: transparent;

			font-family: $font-base;
			@include font-size(13);
			color: $color-text;

			-webkit-appearance: none;
		}

		div.select {
			border: 1px solid $color-1-lighten;
			border-color: rgba($color-1-lighten, 0.6);
		}

		&.error {

			select.select {
				border-color: $color-cancel;
			}
		}
	}

	&.form__input-container--select--sidebar {
		margin-top: 20px;

		@include mq($from: tablet) {
			margin-top: 30px;
			max-width: 90%;
		}
	}

	&.form__input-container--checkbox {
		@include clearfix;
		float: left;
		width: 100%;

		label.form__label--checkbox {
			position: relative;
			padding-left: 20px;
		}
		
		input[type="checkbox"],
		label.form__label--checkbox:before {
			width: 15px;
			height: 15px;
			top: 0;
			left: 0;
			position: absolute;
			cursor: pointer;
		}

		label.form__label--checkbox:before {
			content: '';
			border: 2px solid $color-1-lighten;
			border-color: lighten($color-1-lighten, 15%);
			transition: opacity 0.3s ease-in-out;
		}

		input[type="checkbox"] {
			opacity: 0;
			-webkit-appearance: none;
			display: inline-block;
			vertical-align: middle;
			z-index: 100;

			&:checked + label.form__label--checkbox {
				color: $color-1-darken;

				&:before {
					opacity: 0.8;
				}
			}
		}

		svg {
			position: absolute;
			width: 16px;
			height: 16px;
			top: -2px;
			left: 0;
			pointer-events: none;

			path {
				stroke: $color-1-darken;
				stroke-width: 13px;
				stroke-linecap: round;
				stroke-linejoin: round;
				fill: none;
			}
		}

		&:after {
			content: "!" !important;
			top: 0;
		}
	}

	&.form__input-container--checkbox.form__horaire-day {
		
		label.form__label--checkbox {
			display: inline-block;
			margin-top: 5px;
		}

		svg {
			top: auto;
			bottom: 2px;
		}
	}
}

.form__label {
	position: relative;
	display: block;
	padding: 8px 0;

	@include font-size(13);
	color: $color-text;
	text-align: left;

	transition: color 0.2s ease-in-out;

	&.form__label--outsidefield {
	}

	.current & {
		color: $color-1;
	}

	&.form__label-inner {
		padding: 5px 0 0 10px;

		@include font-size(11);
	}

	span.form__label--info {
		position: absolute;
		width: 100%;
		left: 0;

		span.form__label--info-picto {
			position: absolute;
			right: 0;

			width: 18px;
			height: 18px;
			line-height: 18px;
			border-radius: 50%;
			background-color: $color-1-lighten;
			background-color: lighten($color-1-lighten, 10%);

			font-family: $font-alt;
			@include font-size(13);
			color: $color-text-light;
			text-align: center;

			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			
			transition: background-color 0.2s ease-in-out;
		}

		span.form__label--info-content {
			position: absolute;
			right: 0px;

			width: auto;
			background-color: $white;
			padding-left: 6px;

			font-family: $font-alt;
			@include font-size(13);
			color: $color-1-lighten;
			text-align: right;
			line-height: 15px;

			opacity: 0;

			transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
		}
		
		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {

			span.form__label--info-picto {
				cursor: pointer;
				background-color: $color-1;
			}

			span.form__label--info-content {
				transition: opacity 0.6s ease-in-out, right 0.4s ease-in-out;

				opacity: 1;
				right: 25px;
			}
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: default;
	}
}

.form__input {
	@include input_mobile();
	display: block;
	width: 100%;
	padding: 0 10px;
	height: 37px;
	position: relative;

	font-family: $font-base;
	@include font-size(13);
	text-align: left;

	border: 1px solid $color-1-lighten;
	border-color: rgba($color-1-lighten, 0.6);

	transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out;

	&.form__input-inner {
		padding: 2px 10px 4px 10px;
		border: none;
	}

	&.form__input--presentation {

		@include mq($from: desktop) {
			height: 193px;
		}
	}

	&.form__input--textarea {
		height: auto;
		padding: 10px;
	}

	&.form__input--capitalize {
		text-transform: capitalize;
	}
}

ul.form__horaire {
	margin-top: 0;

	li.form__horaire-day {
		margin-bottom: 15px;
	}

	.form__label {
		padding: 0 0 5px 0;
		text-transform: uppercase;
	}

	.form__horaire-day-am,
	.form__horaire-day-pm {
		
		input[type="text"] {
			float: left;
			box-sizing: border-box;
			margin: 0 4px 4px 0;
			height: 30px;
			line-height: 30px;
			width: calc(50% - 4px);
			padding: 0 5px;

			border: 1px solid $color-1-lighten;
			border-color: rgba($color-1, 0.4);

			font-family: $font-base;
			@include font-size(13);

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.form__label--horaire-day-close {
		display: inline-block;
		margin-top: 4px;

		@include font-size(13);

		input[type="checkbox"].form__horaire-day-close {
			border-radius: 0;
			margin-right: 2px
		}
	}
}

.js .file__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file__input {

	+ label {
		@include font-size(12);
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    cursor: pointer;
	    display: inline-block;
	    overflow: hidden;

	    padding: 10px 20px;
		background-color: $color-1-lighten;
		color: $color-text-light;

		transition: background-color 0.2s ease-in-out;
	}

	&:focus {

		+ label {
		    outline: 1px dotted $black;
		    outline: -webkit-focus-ring-color auto 5px;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {

		+ label {
			background-color: $color-1;
		}
	}
}

.no-js .file__input + label {
    display: none;
}

.form__input-container {

	&.current {

		.form__input {
			border-color: $color-1;
		}
	}

	&.error {

		&:after {
			opacity: 1;
		}

		.form__input {
			border-color: lighten($color-cancel, 0%);
		}
	}
}

.formError {
	width: auto !important;
	margin: 0 !important;
	opacity: 1 !important;
	top: 35px !important;
	left: auto !important;
	right: 26px !important;
	z-index: 100;

	text-align: right;

	.form__input-container--checkbox & {
		top: 0 !important;
	}
}

.formErrorContent {
	width: auto;

	@include font-size(12);
	font-family: $font-alt;
	color: $color-cancel;
	line-height: 1.2em;
}

.form__mention--container {
	float: left;
	width: 100%;
}

.form__mention {
	@include font-size(11);
	font-style: italic;
	color: lighten($color-1, 35%);
}

.form__btn {
	float: left;
	margin: 1.4em 0 0 0;
	
	.btn {
		display: inline-block;
		padding: 15px 30px;
		position: relative;
		border: none;

		background-color: $color-1;

		font-family: $font-base;
		@include font-size(11);
		letter-spacing: 0.1em;
		color: $color-text-light;
		text-transform: uppercase;

		transition: background-color 0.2s ease-in-out;

		@include mq($from: desktop) {
			padding: 20px 40px;
		}

		&:after {
			font-family: $font-icon;
			content: "\e910";
			@include font-size(9);
			color: $color-text-light;

			margin: 0 0 0 4px;

			transition: margin-left 0.2s ease-in-out;
		}
	}
	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {

		.btn {
			background-color: $color-1-lighten;

			&:after {
				margin-left: 10px;
			}
		}
	}
}

/***************************************/
/************** GMP LISTE **************/
/***************************************/
.galerie__localisation {
	margin-top: 8px;
	
	span.icon-pointer {
		display: inline-block;
		margin-right: 3px;
	}
}

/***************************************/
/************ EXPERT DETAIL ************/
/***************************************/
.expert-detail__avatar {
	width: 100%;
	max-width: 45%;
	height: auto;
	margin-bottom: 20px;

	@include mq($from: tablet) {
		max-width: 85%;
	}
	@include mq($from: desktop) {
		max-width: 90%;
	}
}

.expert-detail__name {
	text-transform: capitalize;
	line-height: 1em;

	.expert-societe {
		@include font-size(15);
	}
}

.fullwidth-list {

	@include mq($until: tablet) {
		margin-top: 0;
	}
}

.fullwidth-list__item {
	margin: 10px 0 0 0;
	padding-top: 10px; 
	border-top: 1px solid $color-bg-darken;
	position: relative;

	@include mq($from: wide) {
		padding-top: 15px;
	}

	&:not(.inactive) .toggle-btn__on {
		display: none;
	}

	&.fullwidth-list__item--expert {
		position: relative;

		&:before {
			display: block;
			content: "";
			width: 0;
			height: 4px;
			background-color: $color-1-lighten;

			position: absolute;
			top: -1px;
			left: 0;

			transition: width 0.2s ease-in-out;
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			cursor: pointer;

			&:before {
				width: 100%;

				transition: width 0.4s ease-in-out;
			}
		}
	}
}

.fullwidth-list__title {
	position: relative;
	margin: 0 0 0.8em 0;

	font-family: $font-alt;
	@include font-size(18);

	@include mq($from: desktop) {
		margin: 0 0 1em 0;
		@include font-size(20);
	}

	@include mq($from: wide) {
		@include font-size(21);
	}

	@include mq($from: xwide) {
		@include font-size(22);
	}

	&.fullwidth-list__title--icon {
		padding-left: 36px;

		@include mq($from: desktop) {
			padding-left: 40px;
		}

		@include mq($from: wide) {
			padding-left: 42px;
		}

		@include mq($from: xwide) {
			padding-left: 44px;
		}
	}

	.fullwidth-list__icon {
		display: inline-block;

		position: absolute;
		top: -2px;
		left: 0;

		@include font-size(30);

		@include mq($from: desktop) {
			top: -3px;
			@include font-size(34);
		}

		@include mq($from: wide) {
			@include font-size(36);
		}

		@include mq($from: xwide) {
			top: -4px;
			@include font-size(38);
		}
	}

	&.fullwidth-list__title--alt {

		span {
			display: block;

			&.main-title {
				margin: 0 0 10px 0;

				&:after {
					display: block;
					content: "";
					width: 15px;
					height: 1px;
					background-color: $color-1;
					margin-top: 8px;
				}
			}

			&.subtitle {
				display: block;

				font-family: $font-base;
				@include font-size(14);
			}

			&.subtitle-stronger {
				font-family: $font-semibold;
				color: $color-1-lighten;

				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			&.expire {
				// position: relative;
				// display: inline-block;
				// margin-top: 15px;
				padding: 3px 8px 2px 7px;

				position: absolute;
				top: 0;
				right: 86px;

				font-family: $font-bold;
				@include font-size(14);
				text-transform: uppercase;
				color: $color-cancel;

				border: solid $color-cancel;
				border-color: rgba($color-cancel, 0.8);
				border-width: 1px 0;
				
				&:before,
				&:after {
					content: "";
					width: 3px;
					height: 3px;
					background-color: $color-cancel;
					border-radius: 50%;

					position: absolute;
					top: 10px;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 1px;
				}
			}
		}
	}

	.expert-name {
		margin-bottom: 0.5em;
		text-transform: capitalize;
		line-height: 1em;

		@include mq($until: tablet) {
			max-width: 60%;
		}

		@include mq($from: wide) {
			@include font-size(22);
		}

		&.expertise-titre {
			max-width: 100%;
		}

		span.expert-societe {
			@include font-size(13);
		}

		&:after {
			margin-top: 4px;

			@include mq($from: desktop) {
				margin-top: 5px;
			}
		}
	}

	.expert-localisation {
		font-family: $font-base;
		@include font-size(14);

		span.icon-pointer {
			margin: 0 3px 0 0;
		}

		&.expertise-categorie {
			position: relative;
			padding-left: 25px;
			margin-top: 1em;

			span.icon-cat {
				position: absolute;
				top: -4px;
				left: -3px;

				@include font-size(24);
			}
		}
	}

	ul.expert-category {
		@include clearfix;
		width: auto;
		margin: 0;

		position: absolute;
		top: 0;
		right: 0;

		li {
			position: relative;
			float: left;

			width: 36px;
			height: 36px;
			margin: 0;

			@include mq($from: tablet) {
				width: 40px;
				height: 40px;
			}

			@include mq($from: desktop) {
				width: 44px;
				height: 44px;
			}

			.icon-cat {
				width: 100%;
				height: 100%;
				line-height: 36px;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;

				text-align: center;

				@include mq($from: tablet) {
					line-height: 40px;
				}

				@include mq($from: desktop) {
					line-height: 44px;
				}

				&:before {
					@include font-size(20);

					@include mq($from: desktop) {
						@include font-size(24);
					}
				}
			}

			.hexagon-container {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				.hexagon {
					fill: transparent;
					stroke: $color-1;
				}
			}
		}
	}
}

.fullwidth-list__action-list {

	.adherent-home & {
		margin-top: 1.8em !important;
	}

	li {
		margin-bottom: 5px;

		@include mq($from: tablet) {
			display: inline-block;
			margin: 0 2px 5px 0;
		}
	}
}

.fullwidth-list__content {

	p {
		margin-bottom: 0.9em;
	}
}

.fullwidth-list__item.inactive {
	color: darken($color-bg-darken, 15%);

	.fullwidth-list__title {

		.main-title:after {
			background-color: darken($color-bg-darken, 15%);
		}
	}

	.recap-img {
		background-color: $color-1-darken;
		opacity: 0.5;
	}

	.main-link {
		background-color: darken($color-bg-darken, 15%);

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			background-color: darken($color-bg-darken, 25%);
		}
	}

	.toggle-btn__off {
		display: none;
	}
}


/* 
 ######       ###     ##         ########   ########   ####  ########   
##    ##     ## ##    ##         ##         ##     ##   ##   ##         
##          ##   ##   ##         ##         ##     ##   ##   ##         
##   ####  ##     ##  ##         ######     ########    ##   ######     
##    ##   #########  ##         ##         ##   ##     ##   ##         
##    ##   ##     ##  ##         ##         ##    ##    ##   ##         
 ######    ##     ##  ########   ########   ##     ##  ####  ########   
*/

/****************************************/
/************ GALERIE DETAIL ************/
/****************************************/
.sidebar__title {
	margin: 0 0 0.8em 0 !important;
	font-family: $font-semibold;
}

.sidebar__simple-list {
	margin: 0;

	li {
		margin: 0 0 0.5em 0;
	}
}

.galerie-detail--coordonnees,
.galerie-detail--horaires,
.galerie-detail--category {
	margin: 0 0 30px 0;
}

.galerie-detail--category {
	position: relative;
	padding-left: 26px;
	
	span.icon-cat {
		@include font-size(24);

		position: absolute;
		top: -4px;
		left: -2px;
	}
}

.galerie-detail__map {
	height: 260px;
	margin: 20px auto 0 auto;

	@include mq($from: tablet) {
		height: 300px;
		margin: 40px auto 0 auto;
	}

	@include mq($from: desktop) {
		height: 320px;
	}

	@include mq($from: xwide) {
		height: 340px;
	}
}

.galerie__content {
	overflow: hidden;

	span {
		display: block;
		margin: 0 auto 0.3em auto;

		font-family: $font-base;
		@include font-size(14);
		color: $color-1-lighten;

		@include mq($from: mobileLandscape) {
			@include font-size(13);
		}

		a {
			display: inline-block;
			position: relative;

			transition: color 0.3s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(12);
			}

			&:before,
			&:after {
				display: block;
				content: "";

				width: 0;
				height: 1px;
				background-color: lighten($color-1-lighten, 12%);

				position: absolute;
				bottom: -3px;

				transition: width 0.2s ease-in-out;
			}

			&:before {
				left: 50%;
			}

			&:after {
				right: 50%;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				color: darken($color-1-darken, 10%);

				&:before,
				&:after {
					width: 50%;
				}
			}
		}
	}
}


/* 
########   ##     ##  ########   ##    ##   ########   
##         ##     ##  ##         ###   ##      ##      
##         ##     ##  ##         ####  ##      ##      
######     ##     ##  ######     ## ## ##      ##      
##          ##   ##   ##         ##  ####      ##      
##           ## ##    ##         ##   ###      ##      
########      ###     ########   ##    ##      ##      
*/
/****************************************/
/*********** ÉVÉNEMENT LISTE ************/
/****************************************/
.evenement__localisation {
	margin-top: 0;
}

/****************************************/
/*********** ÉVÉNEMENT DÉTAIL ***********/
/****************************************/
.evenement-detail--categories {
	ul li {
		position: relative;
		padding-left: 30px;

		span {
			position: absolute;
			top: -5px;
			left: 0;

			@include font-size(26);
		}
	}
}

.evenement-detail--list {
	margin: 0 0 20px 0;


	p.list-entete {
		margin: 0 0 1em 0;
	}

	ul {
		margin: 0;
	}

	&.evenement-detail--expert {
			
		ul li {
			position: relative;
			padding-top: 10px;
			border-top: 1px solid $color-bg-darken;

			&:before {
				display: block;
				content: "";
				width: 0;
				height: 4px;
				background-color: $color-1-lighten;

				position: absolute;
				top: -4px;
				left: 0;

				opacity: 0.8;

				transition: width 0.35s ease-in-out, opacity 0.2s ease-in-out;
			}

			.expert-title {

				span {
					display: block;

					&.title {
						font-family: $font-semibold;
						text-transform: uppercase;
						letter-spacing: 0.05em;
					}

					&.expertise {
						font-family: $font-alt;
					}
				}
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				cursor: pointer;

				&:before {
					opacity: 1;
					width: 100%;
				}
			}
		}
	}

	&.evenement-detail--galerie {
		margin: 40px 0 0 0;

		ul li {

			.galerie-container {
				border: 1px solid $color-1-lighten;
				border-color: $color-1-lighten;
				padding: 20px 20px 22px 20px;
				background-color: transparent;

				position: relative;
				z-index: 2;

				@include mq($from: tablet) {
					padding: 15px;
				}

				&:before {
					display: block;
					content: "";
					z-index: 1;

					width: 100%;
					height: 100%;

					position: absolute;
					top: 0;
					left: 0;

					border: 6px solid $color-bg-darken;
					border-color: rgba($color-bg-darken, 0.6);

					transition: border-width 0.3s ease-in-out, border-color 0.3s ease-in-out;

					@include mq($from: tablet) {
						border-width: 4px;
					}
				}

				&:after {
					display: block;
					content: "";
					z-index: -1;

					width: 100%;
					height: 100%;

					position: absolute;
					top: 0;
					left: 0;

					border: 1px solid rgba($color-bg-darken, 0.35);

					transition: border-color 0.2s ease-in-out;
				}

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					cursor: pointer;
					
					&:before {
						border-width: 10px;
						border-color: rgba($color-bg-darken, 0.35);
						
						@include mq($from: tablet) {
							border-width: 8px;
						}
					}

					&:after {
						border-color: $color-1;
					}
				}
			}

			.galerie-title {
				font-family: $font-semibold;
				text-transform: uppercase;
				letter-spacing: 0.05em;

				margin: 0 0 0.8em 0;

				&:after {
					display: block;
					content: "";
					width: 12px;
					height: 1px;
					background-color: $color-1-lighten;
					margin-top: 2px;
				}
			}

			.galerie-link {
				display: inline-block;
				margin-top: 20px;
				padding: 6px 12px;
				background-color: $color-1;

				@include font-size(11);
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: $color-text-light;

				transition: background-color 0.2s ease-in-out;

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					background-color: $color-1-darken;
				}
			}
		}
	}
}


/* 
   ###     ########   ##     ##  ########   ########   ########   ##    ##   ########   
  ## ##    ##     ##  ##     ##  ##         ##     ##  ##         ###   ##      ##      
 ##   ##   ##     ##  ##     ##  ##         ##     ##  ##         ####  ##      ##      
##     ##  ##     ##  #########  ######     ########   ######     ## ## ##      ##      
#########  ##     ##  ##     ##  ##         ##   ##    ##         ##  ####      ##      
##     ##  ##     ##  ##     ##  ##         ##    ##   ##         ##   ###      ##      
##     ##  ########   ##     ##  ########   ##     ##  ########   ##    ##      ##      
*/
.adherent__sidebar--bg {

	@include mq($from: tablet) {
		background: linear-gradient(to right, lighten($color-1-lighten, 48%) 0%, lighten($color-1-lighten, 48%) 37.5%, $white 37.5%,$white 100%);
	}

	@include mq($from: desktop) {
		background: linear-gradient(to right, lighten($color-1-lighten, 48%) 0%, lighten($color-1-lighten, 48%) 33.3333%, $white 33.3333%,$white 100%);
	}

	@include mq($from: xwide) {
		background: linear-gradient(to right, lighten($color-1-lighten, 48%) 0%, lighten($color-1-lighten, 48%) 35%, $white 35%,$white 100%);
	}

	@include mq($from: xxwide) {
		background: linear-gradient(to right, lighten($color-1-lighten, 48%) 0%, lighten($color-1-lighten, 48%) 37.5%, $white 37.5%,$white 100%);
	}

	@include mq($from: xxxwide) {
		background: linear-gradient(to right, lighten($color-1-lighten, 48%) 0%, lighten($color-1-lighten, 48%) 40%, $white 40%,$white 100%);
	}
}

.adherent__sidebar {
	@include clearfix;
	height: auto;
	padding-bottom: 20px;

	@include mq($until: tablet) {
		border-bottom: 1px solid $color-bg-darken;
	}
}

.sidebar-profil,
.sidebar-list {
	@include clearfix;
	width: 100%;

	@include mq($from: tablet) {
		width: 74%;
	}

	@include mq($from: desktop) {
		width: 78%;
	}
}

.sidebar-profil {
	padding-top: 20px;

	@include mq($from: wide) {
		padding-top: 30px;
	}
}

.sidebar-profil__avatar {
	width: 140px;
	height: 140px;
	margin: 0 auto;
	position: relative;

	border-radius: 50%;
	transform: translate3d(0, 0, 0.000001);
	-webkit-backface-visibility: hidden;

	background-color: transparent;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	opacity: 1;

	transition: opacity 0.2s ease-in-out;

	@include mq($from: wide) {
		width: 160px;
		height: 160px;
	}

	a.avatar-edit {
		display: block;
		width: 30px;
		height: 30px;
		line-height: 30px;

		position: absolute;
		top: 5px;
		right: 5px;

		color: $color-bg-darken;
		text-align: center;
		@include font-size(13);

		border-radius: 50%;
		background-color: $color-1-lighten;

		opacity: 0;
		transform: scale(0);

		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

		@include mq($from: wide) {
			width: 30px;
			height: 30px;
			line-height: 30px;

			top: 8px;
			right: 8px;
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			background-color: $color-1;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active,
	.touch &,
	.touchevents & {
		cursor: pointer;
		opacity: 0.9;

		a.avatar-edit {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.sidebar-profil__name {
	margin: 10px auto 0 auto;
	max-width: 100%;
	text-transform: capitalize;
}

.sidebar-profil__edit {
	margin: 10px auto 0 auto;
	max-width: 100%;
	text-align: center;

	a {
		display: inline-block;

		@include font-size(13);
		text-align: center;
		color: lighten($color-1-lighten, 15%);
	}
}

.sidebar-list {

	&.sidebar-list--multi {
		width: 100%;
		> li {
			@include clearfix;
			height: auto;
			line-height: normal;
			padding: 10px 0;

			.strong {
				float: left;
				padding: 0 5px 5px 0;
			}

		}
	}

	> li {
		height: auto;
		line-height: 1.2em;
		padding: 12px 0;
		margin: 0;
		width: 100%;

		.adherent__sidebar & {
			height: 40px;
			line-height: 40px;
			padding: 0;
		}

		border-bottom: 1px solid rgba($color-bg-darken, 0.9);

		&.list-container {
			height: auto;
			// border: none;
			
			ul {
				margin-bottom: 15px;
			}
		}

		> a {
			display: block;
			height: 100%;

			position: relative;
			z-index: 1;

			font-family: $font-semibold;
			@include font-size(13);
			text-transform: uppercase;
			letter-spacing: 0.05em;

			transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;

			&:before {
				display: block;
				content: "";
				height: 40px;
				max-height: 40px;
				width: 0;
				background-color: lighten($color-1-lighten, 42%);

				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				opacity: 0;

				transition: width 0.3s ease-in-out, opacity 0.6s ease-in-out;

				@include mq($until: tablet) {
					background-color: $color-bg-darken;
				}
			}

			&:after {
				display: block;
				content: "";
				height: 3px;
				width: 0;
				background-color: $color-1;

				position: absolute;
				bottom: -1px;
				left: 0;
				z-index: 2;

				transition: width 0.3s ease-in-out;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active,
			&.current {
				padding-left: 10px;
				color: $color-1-darken;

				&:before,
				&:after {
					width: 100%;
				}

				&:before {
					opacity: 1;
				}
			}
		}

		> span.not-link {
			display: block;
			height: 100%;

			position: relative;
			z-index: 1;

			font-family: $font-semibold;
			@include font-size(13);
			text-transform: uppercase;
			letter-spacing: 0.05em;

			&:before {
				display: block;
				content: "";
				height: 40px;
				max-height: 40px;
				width: 0;
				background-color: lighten($color-1-lighten, 42%);

				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				opacity: 0;

				transition: width 0.3s ease-in-out, opacity 0.6s ease-in-out;

				@include mq($until: tablet) {
					background-color: $color-bg-darken;
				}
			}

			&:after {
				display: block;
				content: "";
				height: 3px;
				width: 0;
				background-color: $color-1;

				position: absolute;
				bottom: -1px;
				left: 0;
				z-index: 2;

				transition: width 0.3s ease-in-out;
			}

			&.current {
				padding-left: 10px;
				color: $color-1-darken;

				&:before,
				&:after {
					width: 100%;
				}

				&:before {
					opacity: 1;
				}
			}
		}
	}
}

.sidebar-list--inner {
	@include clearfix;
	margin: 0;
	padding: 10px 0 0 20px;

	border-top: 1px solid rgba($color-bg-darken, 0.7);

	li {
		margin: 0;
		height: 30px;		
		line-height: 30px;

		a {
			display: inline-block;
			position: relative;

			&:before {
				display: inline-block;

				font-family: $font-icon;
				content: "\e910";
				@include font-size(11);

				margin-right: 4px;

				transition: transform 0.3s ease-in-out;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				
				&:before {
					transform: rotate(360deg);
				}
			}

			&.current {
				font-family: $font-semibold;

				&:after {
					display: block;
					content: "";
					width: 100%;
					width: calc(100% - 17px);
					height: 0;

					position: absolute;
					bottom: 6px;
					right: 0;

					border-bottom: 1px dotted $color-1-lighten;

					transition: width 0.2s ease-in-out;
				} 
			}
		}
	}
}

.adherent__content--inner {

	@include mq($from: tablet) {
		padding: 45px 0;
	}

	.page-content {
		margin-bottom: 20px;

		@include mq($from: tablet) {
			max-width: 90%;
		}

		@include mq($from: desktop) {
			margin-bottom: 40px;
		}

		@include mq($from: wide) {
			max-width: 85%;
		}
	}
}

.contact-info {
	padding: 25px 0;
	border-top: 1px solid $color-bg-darken;

	@include mq($from: desktop) {
		padding: 30px 0;
	}

	.subtitle {
		font-family: $font-semibold;
		text-transform: uppercase;
		margin-top: 0.4em;		
	}

	.col-1-3 {
		@include mq($from: tablet) {
			width: 100%;
		}

		@include mq($from: desktop) {
			width: 40%;
		}
	}

	.col-2-3 {
		@include mq($from: tablet) {
			width: 100%;
		}

		@include mq($from: desktop) {
			width: 60%;
		}
	}
}

.contact-info__head {
	@include clearfix;
	margin-bottom: 20px;
	position: relative;
}

.contact-info__photo {
	width: 100px;
	height: auto;
	float: left;
	margin: 0 10px 10px 0;

	@include mq($from: wide) {
		width: 120px;
		margin: 0 15px 15px 0;
	}
}

.contact-info__detail {
	width: auto;
	float: left;

	@include mq($from: desktop) {
		max-width: 45%;
	}

	@include mq($from: wide) {
		max-width: 80%;
	}
}

.contact-info__name {
	max-width: 100%;
	margin-bottom: 0.5em;
	@include font-size(20);

	&:after {
		@include mq($from: desktop) {
			margin-top: 4px;
		}
	}

	@include mq(tablet, desktop) {
		@include font-size(18);
	}
}

.contact-info__name {
	max-width: 100%;
}

/***************************************/
/*************** FACTURE ***************/
/***************************************/
.facture--content,
.message--title,
.message--content {

	@include mq($from: desktop) {
		max-width: 80%;
	}
}

.facture--content {
	margin-top: 1.5em;

	li {
		display: inline-block;
		margin-bottom: 0.25em;

		&:not(:last-of-type):after {
			content: " / "
		}
	}
}

// .facture-list {

// 	a {
// 		border-bottom: 1px solid $color-1-lighten;
// 		border-color: rgba($color-1-lighten, 0.6);

// 		.no-touch &:hover,
// 		.no-touchevents &:hover,
// 		.no-touch &:focus,
// 		.no-touchevents &:focus,
// 		.touch &:active,
// 		.touchevents &:active {
// 			border-color: rgba($color-1-lighten, 0.8);
// 		}
// 	}
// }

.main-link--absolute {
	margin-top: 1.5em;

	@include mq($from: desktop) {
		position: absolute !important;
		right: 0;
		bottom: 0;
	}
}

/***************************************/
/*************** MESSAGE ***************/
/***************************************/
.message--title {

	@include mq($from: desktop) {
		margin-bottom: 0.7em; 
	}
}

.message-detail--title {
	font-family: $font-bold;
	text-transform: uppercase;
	color: $color-1-lighten;
}

.message-detail--date {
	position: absolute;
	top: 0;
	right: 0;

	font-family: $font-alt;
}

.message-detail--service {
	font-family: $font-semibold;
	color: $color-1-lighten;
	margin-bottom: 0.3em !important;
}


/* 
########   ##         ##     ##  ########   ##          #######      ###     ########   
##     ##  ##         ##     ##  ##     ##  ##         ##     ##    ## ##    ##     ##  
##     ##  ##         ##     ##  ##     ##  ##         ##     ##   ##   ##   ##     ##  
########   ##         ##     ##  ########   ##         ##     ##  ##     ##  ##     ##  
##         ##         ##     ##  ##         ##         ##     ##  #########  ##     ##  
##         ##         ##     ##  ##         ##         ##     ##  ##     ##  ##     ##  
##         ########    #######   ##         ########    #######   ##     ##  ########   
*/
/***************************************/
/*************** OVERLAY ***************/
/***************************************/
.overlay-plupload {
	height: 100%;
	width: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 160;
	
	background-color: transparent;
}

/***************************************/
/************* WIDGET ROW **************/
/***************************************/
.widget-container {
	@include clearfix;
	width: 100%;
	width: calc(100% + 10px);
	margin: -5px 0 0 -5px;

	// float: left;
	position: relative;

	&.full {
		width: 100%;
	}
}

.widget-row {
	@include clearfix;
	padding: 0 5px;
}

.widget {
	@include clearfix;
	width: 100%;
	margin: 5px 0 15px 0;
}

.widget-inner {
	@include clearfix;
	padding: 10px;
	position: relative;

	border-radius: 2px;
	overflow: hidden;

	@include mq($from: desktop) {
		padding: 20px;
	}

	&.inc-absolute-btn {
		padding-bottom: 65px;
	}
}

/***************************************/
/************* WIDGET PICT *************/
/***************************************/
.widget-pict-container {
	@include clearfix;
	width: 100%;
}

.widget-pict {
	@include clearfix;
	width: percentage(1/2);
	margin: 0 !important;
	padding: 5px;

	float: left;
	position: relative;
	border: none;

	cursor: move;

	@include mq($from: mobileLandscape) {
		width: percentage(1/4);
	}

	@include mq($from: desktop) {
		width: percentage(1/6);
	}

	img {
		width: 100%;

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus {
			// cursor: move;
		}
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
	}

	&.placeholder {
		margin: 0 !important;
		background: none !important;

		img {
			border: 1px dashed darken($color-bg-darken, 10%);
		}
	}

	&.ui-sortable-helper {
		img {
			box-shadow: 0 0 15px rgba($black, 0.5);
		}
	}

	.widget-pict-legend {
		position: absolute;
		bottom: 5px;
		left: 5px;
		right: 5px;

		background-color: $white;

		transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

		.no-touch & {
			opacity: 0;
			visibility: hidden;
		}

		input[type="text"] {
			@include input_mobile();
			width: 100%;
			padding: 8px 6px;
			border: 1px solid $color-bg-darken;
			border-top: none;
			background-color: transparent;

			@include font-size(12);

			@include mq($from: xwide) {
				padding: 6px;
			}
		}

		.tab-lang {
			position: absolute;
			top: -25px;
			left: 0;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		.widget-pict-legend {
			opacity: 1;
			visibility: visible;
		}
	}

	.widget-pict-legend.active {
		opacity: 1;
		visibility: visible;
	}
}

.widget-file {
	@include clearfix;
	width: 100%;

	.widget-file--container {
		min-height: 40px;
		background-color: lighten($color-bg-darken, 5%);
		padding: 6px 10px;

		.widget-file--content {
			max-width: 90%;
			width: 90%;
			@include font-size(13);

			&:before {
				display: inline-block;
				margin: 0 6px 0 0;

				font-family: $font-icon;
				content: "\e916";
				@include font-size(12);
			}
		}

		.btn-hover {
			visibility: visible;
			opacity: 1;

			cursor: pointer;

			a.btn-del {
				background-color: $color-bg-darken !important;

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					background-color: darken($color-bg-darken, 5%) !important;
				}
			}
		}

		.widget-file-legend {
			top: 5px;
			height: 100%;
			height: calc(100% - 10px);
			width: calc(100% - 40px);

			background-color: rgba($white, 0.9);

			input[type="text"] {
				height: 100%;
				border: 1px solid lighten($color-1-lighten, 30%);
			}
		}
	}
}

/************* HOVER PICT *************/
.btn-hover {
	display:  block;
	width:    30px;
	height:   100%;
	position: absolute;
	top:      5px;
	right:    5px;

	.form-cell & {
		top: 0;
		right: -50px;
		background-color: $color-1;

		a:last-of-type {
			position: absolute;
			bottom: 0;
		}
	}

	opacity:  0;
	visibility: hidden;

	transition: bopacity 0.2s ease-in-out, right 0.2s ease-in-out;

	.widget-pict & {
		overflow: hidden;
	}

	.form-cell:hover &,
	.widget-pict:hover &,
	.touch .widget-pict &,
	.touch .form-cell & {
		opacity: 1;
		visibility: visible;
		right: 5px;
	}

	.form-cell:hover &,
	.touch .form-cell & {
		right: 0;
	}
}

.btn-edit,
.btn-del {
	display: block;
	width: 30px;
	height: 30px;
	line-height: 29px;

	background-color: $color-bg;
	background-color: rgba($color-bg, 0.9) !important;

	@include font-size(16);
	text-align: center;
	color: $color-1-lighten;

	transition: background-color 0.2s ease-in-out;
	
	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		background-color: $color-bg !important;
	}
}

/***************************************/
/************* UPLOAD ZONE *************/
/***************************************/
ul.sortable {
	margin: 0;
}

.upload-zone {
	display: block;
	padding: 15px;
	background-color: lighten($color-bg-darken, 8%);
	border: 1px dashed darken($color-bg-darken, 10%);

	@include mq($from: desktop) {
		padding: 30px 25px;
	}

	&.upload-zone--avatar {
		background-color: transparent;
		border: none;
		padding: 0;

		@include mq($from: desktop) {
			padding: 0;
		}
		
		.upload-instructions {
			padding: 0;
			text-align: center;
			@include font-size(12);
			&:before {
				display: none;

			}
			span {
				@include font-size(12);
			}
			@include mq($from: desktop) {
				padding: 0;
				@include font-size(14);
			}
		}

		.upload-feedback {
			width: 100%;
			height: 100%;

			margin: 0;
			padding: 0;

			position: absolute;
			z-index: 1;

			@include mq($from: desktop) {
				margin: 0;
				padding: 0;
			}

			border: 0;

			color: darken($color-bg-darken, 10%);
			transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

			.fileItem {
				position: absolute;
				width: 100%;
				height: 100%;

				padding: 0;


				.name,
				.size {
					display: none;
				}

				.plupload_progress,
				.spinner-container {
					display: block;
				}

				.plupload_progress {
					width: 100%;
					height: 100%;
					overflow: hidden;

					margin: 0;
					padding: 0;

					position: absolute;
					border: 0;

					transition: border-color 0.2s ease-in-out;

					@include mq($from: desktop) {
						margin: 0;
					}
				}

				.plupload_progress_bar {
					width: 100%;
					height: 0%;
					display: block;

					position: absolute;
					bottom: 0;
					left: 0;
					top: auto;

					background-color: rgba($color-1-lighten, 0.8) !important;

					transition: background-color 0.2s ease-in-out, height 0.2s linear;
				}

				@keyframes loader-rotation {
					0% {
						transform: rotate(0deg);
					}

					16.66% {
						transform: rotate(60deg);
					}
						
					33.33% {
						transform: rotate(120deg);
					}

					50% {
						transform: rotate(180deg);
					}
					
					66.66% {
						transform: rotate(220deg);
					}

					83.33% {
						transform: rotate(280deg);
					}

					100% {
						transform: rotate(360deg);	
					}
				}

				.plupload_working {
					position: absolute;
					width: 30px;
					height: 30px;
					top: 50%;
					left: 50%;
					margin-left: -15px;
					margin-top: -15px;
					
					background: transparent url('#{$image-path}loader-hexa.png') 0 0 no-repeat;
					background-size: 30px;

					animation: loader-rotation 2.5s infinite ease-in-out;
					transform-origin: 50% 50%;

					&.no-spinner {
						background: none;
					}
				}

				.spinner-container {
					display: none;
				}

				.spinner {
					display: none;
				}
			}
		}

	}

	.no-touch &.upload-zone--avatar:hover,
	.no-touchevents &.upload-zone--avatar:hover,
	.no-touch &.upload-zone--avatar:focus,
	.no-touchevents &.upload-zone--avatar:focus,
	.touch &.upload-zone--avatar:active,
	.touchevents &.upload-zone--avatar:active {
		border: none;

		.upload-feedback {
			border:none;
		}
	}

	.upload-instructions {
		width: 100%;
		padding-left: 35px;
		position: relative;

		font-family: $font-semibold;
		@include font-size(18);
		color: darken($color-bg-darken, 15%);
		line-height: 1.1em;
		overflow: hidden;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		transition: color 0.2s ease-in-out;

		@include mq($from: desktop) {
			padding-left: 45px;
			@include font-size(20);
		}

		&:before {
			display: inline-block;

			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -25px;

			content: "+";
			font-family: $font-semibold;
			@include font-size(50);
			line-height: 50px;

			@include mq($from: desktop) {
				left: 2px;
				@include font-size(54);
			}
		}

		&.upload-instructions--file {

			&:before {
				// top: 40%;

				font-family: $font-icon;
				content: "\e915";
				@include font-size(24);
				line-height: 24px;
			}
		}

		span {
			display: block;
			margin-top: 0.5em;

			font-family: $font-base;
			@include font-size(15);
			line-height: 1.2em;

			@include mq($from: desktop) {
				margin-top: 0.6em;
				@include font-size(16);
			}
		}
	}

	.upload-feedback {
		width: 100%;
		margin-top: 15px;
		padding-top: 10px;

		position: relative;
		z-index: 1;

		border-top: 1px solid darken($color-bg-darken, 10%);
		border-top: 1px solid rgba(darken($color-bg-darken, 10%), 0.6);

		@include font-size(16);
		color: darken($color-bg-darken, 10%);

		transition: border 0.2s ease-in-out, color 0.2s ease-in-out;

		@include mq($from: desktop) {
			margin-top: 30px;
			padding-top: 20px;
		}

		.fileItem {
			@include clearfix;
			padding: 5px 0;

			.name,
			.size,
			.plupload_progress,
			.spinner-container {
				display: block;
			}

			.name,
			.size {
				display: inline-block;

				@include font-size(14);
				color: lighten($color-1-lighten, 25%);
			}

			.name {
				float: left;
				width: 70%;
			}

			.size {
				float: right;
				width: 30%;

				text-align: right;
			}

			.plupload_progress {
				width: 100%;
				height: 30px;
				line-height: 28px;

				margin-top: 10px;
				padding: 0 10px;

				position: relative;
				border: 1px solid darken($color-bg-darken, 15%);

				@include font-size(14);

				transition: border-color 0.2s ease-in-out;

				@include mq($from: desktop) {
					margin-top: 12px;
				}
			}

			.plupload_progress_bar {
				display: inline-block;
				height: 28px;

				position: absolute;
				top: 0;
				left: 0;

				background-color: darken($color-bg-darken, 15%);

				transition: background-color 0.2s ease-in-out;
			}

			.spinner-container {
				display: none;
			}

			.spinner {
				display: none;
				top: 30%;
				left: 60%;
			}
		}
	}

	transition: background-color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		cursor: pointer;
		background-color: lighten($color-1-lighten, 5%);
		border-color: $color-bg;

		.upload-instructions {
			color: $color-text-light;
		}

		.upload-feedback {
			border-top: 1px solid $color-text-light;
			border-color: rgba($color-text-light, 0.4);

			color: $color-text-light;

			.fileItem .name,
			.fileItem .size        { color: $color-text-light; }
			.plupload_progress     { border-color: $color-text-light; }
			.plupload_progress_bar { background-color: $color-text-light; }
		}
	}
}

/* 
 ######     #######   ##     ##  ##     ##     ###     ##    ##   ########   ########   
##    ##   ##     ##  ###   ###  ###   ###    ## ##    ###   ##   ##     ##  ##         
##         ##     ##  #### ####  #### ####   ##   ##   ####  ##   ##     ##  ##         
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   ##     ##  ######     
##         ##     ##  ##     ##  ##     ##  #########  ##  ####   ##     ##  ##         
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   ##     ##  ##         
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   ########   ########   
*/
.form__input-container--commande {

	@include mq($from: desktop) {
		margin-bottom: 15px !important;
	}
}

.commande-recap__label {
	display: block;
	margin-bottom: 0.5em;
	font-family: $font-semibold;
}
.commande-recap__value {
	display: block;
	padding-bottom: 1em;
	position: relative;

	&:after {
		display: block;
		width: 30px;
		height: 1px;
		content: "";
		position: absolute;
		bottom: 0;

		background-color: $color-bg-darken;

		@include mq($from: wide) {
			width: 60px;
		}
	}

	.form__input-container--commande:last-of-type & {
		padding-bottom: 0;

		&:after {
			display: none;
		}
	}
}

.recap-img--container {
	@include clearfix;

	.expert-detail & {
		margin-top: 20px;
	}

	.adherent-home & {
		position: absolute;
		top: 0; right: 0;
	}
}

.recap-img {

	.expert-detail & {
		width: 50px;
		height: 50px;
		float: left;
		margin: 0 3px 3px 0;
	}
}

.facturation-edit-toggle {
	display: inline-block;
	padding: 5px 14px 4px 14px;
	margin: 0 0 5px 0;
	border: 1px solid $color-1-lighten; 
	border-color: rgba($color-1-lighten, 0.6);
	background-color: rgba($color-1-lighten, 0.1);

	font-family: $font-semibold;
	text-transform: uppercase;
	@include font-size(12);

	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		border-color: rgba($color-1-lighten, 0.8);
		background-color: rgba($color-1-lighten, 0.2);
		color: darken($color-text, 10%);
	}
}

.facturation-edit form.contact-form {
	width: 100%;
}

.price {
	display: inline-block;
	font-family: $font-bold;
	font-size: 120%;
}

.price--old {
	display: inline-block;
	margin-right: 5px;
	text-decoration: line-through;
	color: grey;
}

.promo-code {
	@include clearfix;

	strong {
		font-family: $font-bold;
	}
}

.promo-code__link {
	display: inline-block;
	margin-bottom: 10px;
}

.code-promo__input {
	
	.form__input {
		display: inline-block;
		margin-right: 4px;
		max-width: 200px;
	}

	.main-link {
		height: 37px;
		border: 1px solid $color-1;
	}
}

/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
	background-color: $color-1-lighten;

	p, a {
		@include font-size(12);
		color: $color-text-light;

		@include mq($from: desktop) {
			@include font-size(13);
		}

		.no-touch & {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}

	.footer--left {
		@include mq($until: tablet) {
			margin: 0 0 15px 0;
		}

		@include mq($from: tablet) {
			float: left;
			margin: 0;
		}

		li {
			margin: 0;

			@include mq($from: tablet) {
				display: inline-block;
			}
			
			&:not(:last-of-type):after {

				@include mq($from: tablet) {
					display: inline-block;
					content: "-";
					margin: 0 2px 0 5px;

					font-family: $font-light;
					color: $color-text-light;
				}
			}

			a {
				font-family: $font-light;
				border-bottom: 1px solid transparent;

				transition: border-color 0.2s ease-in-out;

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					border-color: rgba($white, 0.5);
					color: $color-text-light;
				}
			}
		}
	}

	.footer--right {

		@include mq($from: tablet) {
			float: right;
			width: auto;
		}

		.copyright {
			font-family: $font-alt;

			@include mq($from: tablet) {
				float: left;
			}
		}

		.social-list {
			@include mq($until: tablet) {
				margin: 10px 0 0 0;
			}

			@include mq($from: tablet) {
				float: right;
				margin: -2px 0 0 10px;
			}

			@include mq($from: desktop) {
				margin: -3px 0 0 10px;
			}

			li {
				display: inline-block;
				margin: 0 2px 0 0;

				&:last-of-type {
					margin: 0;
				}

				@include mq($from: tablet) {
					margin: 0 1px 0 0;
				}

				a {
					display: block;
					width: auto;
					height: auto;
					position: relative;

					border-radius: 50%;
					border: 1px solid $white;
					border-color: rgba($white, 0.5);

					transition: border-color 0.2s ease-in-out;

					&:before {
						display: block;
						width: 30px;
						height: 30px;
						line-height: 29px;

						position: relative;
						z-index: 1;

						@include font-size(18);
						color: $white;
						text-align: center;

						transition: color 0.2s ease-in-out;

						@include mq($from: tablet) {
							width: 22px;
							height: 22px;
							line-height: 22px;
							@include font-size(15);
						}

						@include mq($from: desktop) {
							width: 24px;
							height: 24px;
							line-height: 24px;
							@include font-size(15);
						}
					}

					&:after {
						display: block;
						width: 100%;
						height: 100%;
						z-index: 0;

						position: absolute;
						top: 0;
						left: 0;
						
						border-radius: 50%;
						content: '';
						box-sizing: content-box;

						background: $white;
						
						transform: scale(1.4);
						opacity: 0;

						transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
					}

					.no-touch &:hover,
					.no-touchevents &:hover,
					.no-touch &:focus,
					.no-touchevents &:focus,
					.touch &:active,
					.touchevents &:active {
						border-color: $white;

						&:before {
							color: $color-1-lighten;
						}

						&:after {
							transform: scale(1);
							opacity: 1;
						}
					}		
				}
			}
		}
	}
}

/* 
##         ####   ######    ##     ##  ########    ######       ###     ##         ##         ########   ########   ##    ##   
##          ##   ##    ##   ##     ##     ##      ##    ##     ## ##    ##         ##         ##         ##     ##   ##  ##    
##          ##   ##         ##     ##     ##      ##          ##   ##   ##         ##         ##         ##     ##    ####     
##          ##   ##   ####  #########     ##      ##   ####  ##     ##  ##         ##         ######     ########      ##      
##          ##   ##    ##   ##     ##     ##      ##    ##   #########  ##         ##         ##         ##   ##       ##      
##          ##   ##    ##   ##     ##     ##      ##    ##   ##     ##  ##         ##         ##         ##    ##      ##      
########   ####   ######    ##     ##     ##       ######    ##     ##  ########   ########   ########   ##     ##     ##      
*/
.lg-backdrop {
	background-color: rgba($color-1-darken, 0.85);
}

.lg-toolbar,
.lg-sub-html {
	background-color: transparent;
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
	background-color: transparent;
	color: lighten($color-1-lighten, 35%);

	font-family: $font-icon;
	@include font-size(28);

	transition: color 0.2s ease-in-out;
}

.lg-actions .lg-prev {
	transform: rotate(180deg);

	&:after {
		content: "\e910";
	}
}

.lg-actions .lg-next {

	&:before {
		content: "\e910";
	}
}

.lg-toolbar .lg-icon {
	color: lighten($color-1-lighten, 35%);

	transition: color 0.2s ease-in-out;
}

.lg-toolbar .lg-close {
	height: 40px;
	line-height: 40px;
	padding: 0;
	margin: 7px 0 0 0;

	font-family: $font-icon;
	@include font-size(40);

	transition: transform 0.2s ease-in-out;

	@include mq($from: tablet) {
		margin: 12px 10px 0 0;
	}

	&:after {
		content: "\e903" !important;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		transform: rotate(180deg);
	}
}


/* 
##     ##  ##     ##  ##         ########   ####  ########   ##         ########         ######    ########   ##         ########    ######    ########   
###   ###  ##     ##  ##            ##       ##   ##     ##  ##         ##              ##    ##   ##         ##         ##         ##    ##      ##      
#### ####  ##     ##  ##            ##       ##   ##     ##  ##         ##              ##         ##         ##         ##         ##            ##      
## ### ##  ##     ##  ##            ##       ##   ########   ##         ######           ######    ######     ##         ######     ##            ##      
##     ##  ##     ##  ##            ##       ##   ##         ##         ##                    ##   ##         ##         ##         ##            ##      
##     ##  ##     ##  ##            ##       ##   ##         ##         ##              ##    ##   ##         ##         ##         ##    ##      ##      
##     ##   #######   ########      ##      ####  ##         ########   ########         ######    ########   ########   ########    ######       ##      
 */


.ms-parent{
	width:100% !important;
}

.ms-choice{
	@include font-size(12);
	width:100%;
	border:none;
	line-height:40px;
	height:40px;
	padding: 2px 10px 10px 10px;
	border-radius: 2px;
	color:$color-text;
	font-family: $font-base;
}

.ms-choice > span.placeholder {
    color: $color-text;
}

.ms-drop {
	border: 1px solid #ddd;
	margin-top:5px;
	border-radius: 2px;
}

.ms-drop.bottom {
	box-shadow: none;
}

.ms-drop ul {
    padding: 0;
}

.ms-drop ul > li label {
	padding:8px 8px 8px 25px;
	line-height:20px;
	min-height:20px;
	border-bottom:1px solid #eee;
	font-family: $font-base;
	color:#666;
	@include font-size(12);
	.no-touch &:hover, .no-touch &:focus {
		background: #F0F0F0;
	}

	.select-one &{
		padding-left:8px;
	}

}

.search__fields .selectric .select-one label{
	padding-left:30px !important;
	&:before {
		left: 10px;
	}
}

.ms-drop ul > li.ms-select-all label{
	padding:8px;
}

.ms-drop ul > li label.optgroup {
    color:darken(#666,20%);
    text-transform: uppercase;
    padding:8px;
}

.ms-choice > div{
	background:none;border: 4px solid transparent;
	border-top-color: $color-1-darken;
	border-bottom: none;
	width: 5px;
    height: 5px;
    top:50%;
    right:15px;
    margin-top:-2px;
}
