/* 
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */

.row {
	display: block;
	@include clearfix;
	margin: -$grid-gutter-base;

	@include mq($from: tablet) {
		margin: -$grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		margin: -$grid-gutter-desktop;
	}

	@include mq($from: wide) {
		margin: -$grid-gutter-wide;
	}

	&.add-vertical-padding {
		@include mq($until: tablet) {
			padding-top: $grid-gutter-base;
			padding-bottom: $grid-gutter-base;
		}
	}
}

.col-1-2,
.col-2-2,
.col-1-3,
.col-1-3-home,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4,
.col-1-6,
.col-5-6 {
	display: block;
	width: 100%;
	padding: $grid-gutter-base;
	float: left;

	@include mq($from: tablet) {
		padding: $grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		padding: $grid-gutter-desktop;
	}

	@include mq($from: wide) {
		padding: $grid-gutter-wide;
	}
}

.col-1-2 {
	@include mq($from: tablet) {
		width: percentage( 1/2 );
	}
}

.col-1-3 {
	@include mq($from: tablet) {
		width: 40%;
	}

	@include mq($from: desktop) {
		width: percentage( 1/3 );
	}
}

.col-1-3-home {
	@include mq($from: tablet) {
		width: percentage(1/2);
	}

	@include mq($from: desktop) {
		width: percentage( 1/3 );
	}
}

.col-2-3 {
	@include mq($from: tablet) {
		width: 60%;
	}

	@include mq($from: desktop) {
		width: percentage( 2/3 );
	}
}

.col-1-4 {
	// @include mq($from: mobileLandscape) {
	// 	width: percentage( 2/4 );
	// }

	@include mq($from: tablet) {
		width: percentage( 1/3 );
	}

	@include mq($from: desktop) {
		width: percentage( 1/4 );
	}
}

.col-2-4 {
	@include mq($from: tablet) {
		width: percentage( 2/4 );
	}
}

.col-3-4 {
	@include mq($from: tablet) {
		width: percentage( 2/3 );
	}

	@include mq($from: desktop) {
		width: percentage( 3/4 );
	}
}

.col-1-6 {

	@include mq($from: tablet) {
		width: percentage( 1/3 );
	}

	@include mq($from: desktop) {
		width: percentage( 1/4 );
	}

	@include mq($from: wide) {
		width: percentage( 1/5 );
	}

	@include mq($from: xwide) {
		width: percentage( 1/6 );
	}
}

.col-5-6 {

	@include mq($from: tablet) {
		width: percentage( 2/3 );
	}

	@include mq($from: desktop) {
		width: percentage( 3/4 );
	}

	@include mq($from: wide) {
		width: percentage( 4/5 );
	}

	@include mq($from: xwide) {
		width: percentage( 5/6 );
	}
}
