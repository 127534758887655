/***** RALEWAY Regular *****/
@font-face {
    font-family: 'ralewayregular';
    src: url('../fonts/raleway-regular-webfont.eot');
    src: url('../fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-regular-webfont.woff2') format('woff2'),
         url('../fonts/raleway-regular-webfont.woff') format('woff'),
         url('../fonts/raleway-regular-webfont.ttf') format('truetype'),
         url('../fonts/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Semibold *****/
@font-face {
    font-family: 'ralewaysemibold';
    src: url('../fonts/raleway-semibold-webfont.eot');
    src: url('../fonts/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-semibold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-semibold-webfont.woff') format('woff'),
         url('../fonts/raleway-semibold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Bold *****/
@font-face {
    font-family: 'ralewaybold';
    src: url('../fonts/raleway-bold-webfont.eot');
    src: url('../fonts/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-bold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-bold-webfont.woff') format('woff'),
         url('../fonts/raleway-bold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Extrabold *****/
@font-face {
    font-family: 'ralewayextrabold';
    src: url('../fonts/raleway-extrabold-webfont.eot');
    src: url('../fonts/raleway-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-extrabold-webfont.woff') format('woff'),
         url('../fonts/raleway-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-extrabold-webfont.svg#ralewayextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Light *****/
@font-face {
    font-family: 'ralewaylight';
    src: url('../fonts/raleway-light-webfont.eot');
    src: url('../fonts/raleway-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-light-webfont.woff2') format('woff2'),
         url('../fonts/raleway-light-webfont.woff') format('woff'),
         url('../fonts/raleway-light-webfont.ttf') format('truetype'),
         url('../fonts/raleway-light-webfont.svg#ralewaylight') format('svg');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'ralewaythin';
//     src: url('../fonts/raleway-thin-webfont.eot');
//     src: url('../fonts/raleway-thin-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/raleway-thin-webfont.woff2') format('woff2'),
//          url('../fonts/raleway-thin-webfont.woff') format('woff'),
//          url('../fonts/raleway-thin-webfont.ttf') format('truetype'),
//          url('../fonts/raleway-thin-webfont.svg#ralewaythin') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

/***** PLAYFAIR DISPLAY Italic *****/
@font-face {
    font-family: 'playfair_displayitalic';
    src: url('../fonts/playfairdisplay-italic-webfont.eot');
    src: url('../fonts/playfairdisplay-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-italic-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-italic-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-italic-webfont.svg#playfair_displayitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** FONT ICON *****/
@font-face {
    font-family: 'operavendome';
    src: url('../fonts/operavendome.eot?lhi97l');
    src: url('../fonts/operavendome.eot?lhi97l#iefix') format('embedded-opentype'),
         url('../fonts/operavendome.ttf?lhi97l') format('truetype'),
         url('../fonts/operavendome.woff?lhi97l') format('woff'),
         url('../fonts/operavendome.svg?lhi97l#operavendome') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'operavendome' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e917";
}
.icon-file-o:before {
  content: "\e916";
}
.icon-cloud-download:before {
  content: "\e915";
}
.icon-delete:before {
  content: "\e912";
}
.icon-edit:before {
  content: "\e911";
}
.icon-social-twitter:before {
  content: "\e900";
}
.icon-social-facebook:before {
  content: "\e901";
}
.icon-infinite:before {
  content: "\e914";
}
.icon-caps:before {
  content: "\e906";
}
.icon-hammer:before {
  content: "\e913";
}
.icon-chevron-right:before {
  content: "\e910";
}
.icon-pointer:before {
  content: "\e902";
}
.icon-cross:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-vase:before {
  content: "\e919";
}
.icon-toy:before {
  content: "\e918";
}
.icon-hexagon:before {
  content: "\e90f";
}
.icon-auto:before {
  content: "\e907";
}
.icon-frame:before {
  content: "\e908";
}
.icon-bottle:before {
  content: "\e909";
}
.icon-book:before {
  content: "\e90a";
}
.icon-coins:before {
  content: "\e90b";
}
.icon-hemet:before {
  content: "\e90c";
}
.icon-ring:before {
  content: "\e90d";
}
.icon-shell:before {
  content: "\e90e";
}